import React, {useState, useContext} from 'react';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';

import DashboardIcon from '@mui/icons-material/Dashboard';
import ReportIcon from '@mui/icons-material/Report';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import InsightsIcon from '@mui/icons-material/Insights';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import PersonIcon from '@mui/icons-material/Person';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import SensorsIcon from '@mui/icons-material/Sensors';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import MapIcon from '@mui/icons-material/Map';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import TuneIcon from '@mui/icons-material/Tune';

import {Link} from "react-router-dom";

import logo from './../img/logo.png';
import UserContext from "../context/UserContext";

function NavigationMenu(props) {
    const User = useContext(UserContext);
    const [settingsOpen, setSettingsOpen] = useState(false);

    return (
        <React.Fragment>
            <Toolbar sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <img src={logo} alt="ShipTrack" width="200"/>
            </Toolbar>
            <Divider />
            <List dense={true}>
                <ListItemButton component={Link} to="/">
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard"/>
                </ListItemButton>
                <ListItemButton component={Link} to="/map">
                    <ListItemIcon>
                        <MapIcon />
                    </ListItemIcon>
                    <ListItemText primary="Map"/>
                </ListItemButton>
                <ListItemButton component={Link} to="/insight">
                    <ListItemIcon>
                        <InsightsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Insights"/>
                </ListItemButton>
                {
                    /*
                <ListItemButton component={Link} to="/ticket">
                    <ListItemIcon>
                        <HelpCenterIcon />
                    </ListItemIcon>
                    <ListItemText primary="Tickets"/>
                </ListItemButton>

                     */
                }
                <ListItemButton component={Link} to="/alarm">
                    <ListItemIcon>
                        <NotificationsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Alarms"/>
                </ListItemButton>
                {
                    !!User.admin &&
                    <React.Fragment>
                        <ListItemButton onClick={() => {
                            setSettingsOpen(!settingsOpen);
                        }}>
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Configuration" />
                            {settingsOpen ? <ExpandMore /> : <ChevronRightIcon />}
                        </ListItemButton>
                        <Collapse in={settingsOpen}>
                            <List component="div" disablePadding dense={true}>
                                <ListItemButton sx={{ pl: 4 }} component={Link} to="/user">
                                    <ListItemIcon>
                                        <PersonIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Users" />
                                </ListItemButton>
                                <ListItemButton sx={{ pl: 4 }} component={Link} to="/ship">
                                    <ListItemIcon>
                                        <DirectionsBoatIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Ships" />
                                </ListItemButton>
                                <ListItemButton sx={{ pl: 4 }} component={Link} to="/group">
                                    <ListItemIcon>
                                        <GroupWorkIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Groups" />
                                </ListItemButton>
                                <ListItemButton sx={{ pl: 4 }} component={Link} to="/alarmdefinition">
                                    <ListItemIcon>
                                        <NotificationImportantIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Alarm definitions" />
                                </ListItemButton>
                                <ListItemButton sx={{ pl: 4 }} component={Link} to="/device">
                                    <ListItemIcon>
                                        <SensorsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Devices" />
                                </ListItemButton>
                                <ListItemButton sx={{ pl: 4 }} component={Link} to="/volume_map">
                                    <ListItemIcon>
                                        <TuneIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Volume Maps" />
                                </ListItemButton>
                            </List>
                        </Collapse>
                    </React.Fragment>
                }
            </List>
        </React.Fragment>
    );
}

export default NavigationMenu;