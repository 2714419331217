/**
 * For internal use only
 * Never share this code outside the organization
 * Created by Robin
 * Created on 07/03/2024
 */
import React, {useCallback, useContext, useEffect, useState} from "react";
import APIContext from "../context/APIContext";
import Card from "@mui/material/Card";
import ListCardHeader from "../components/ListCardHeader";
import CardContent from "@mui/material/CardContent";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import AlarmDefinitionButton from "../components/AlarmDefinitionButton";
import ShipButton from "../components/ShipButton";
import GroupButton from "../components/GroupButton";
import Grid from "@mui/material/Grid";
import AlarmIcon from "../components/AlarmIcon";
import InsightButton from "../components/InsightButton";
import Box from "@mui/material/Box";
import moment from "moment";

import ArchiveIcon from '@mui/icons-material/Archive';
import Button from "@mui/material/Button";
import ConfirmDeleteDialog from "../components/ConfirmDeleteDialog";

export default function AlarmList(props) {
    const API = useContext(APIContext);
    const [alarms, setAlarms] = useState(false);
    const [closingAlarm, setClosingAlarm] = useState(false);

    const reload = () => {
        API.getAllOpenAlarms().then((alarms) => {
            setAlarms(alarms);
        });
    };

    useEffect(() => {
        reload();
    }, [API]);

    const closeAlarm = () => {
        API.closeAlarm(closingAlarm.id).then(() => {
           reload();
        });
    };

    const AlarmListCard = () => {
        if (alarms === false) {
            return (
                <Card>
                    <ListCardHeader title="Alarm list" />
                    <CardContent>
                        <Skeleton variant="rounded" height={20} width="50%" animation="pulse"/>
                        <br />
                        <Skeleton variant="rounded" height={20} width="25%" animation="pulse"/>
                        <br />
                        <Skeleton variant="rounded" height={20} width="75%" animation="pulse"/>
                        <br />
                        <Skeleton variant="rounded" height={20} width="50%" animation="pulse"/>
                    </CardContent>
                </Card>
            );
        } else {
            if (alarms.length === 0) {
                return (
                    <Card>
                        <ListCardHeader title="Alarm list"/>
                        <CardContent sx={{
                            textAlign: 'center'
                        }}>
                            No active alarms!
                        </CardContent>
                    </Card>
                );
            } else {
                return (
                    <React.Fragment>
                        <Card>
                            <ListCardHeader title="Alarm list"/>
                            <CardContent sx={{
                                paddingTop: 0,
                                paddingLeft: 0,
                                paddingRight: 0,
                                paddingBottom: '0px !important'
                            }}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Ship</TableCell>
                                            <TableCell>First trigger</TableCell>
                                            <TableCell>Last trigger</TableCell>
                                            <TableCell align="right">Close</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {alarms.map((alarm) => (
                                            <TableRow
                                                key={alarm.id}

                                            >
                                                <TableCell>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '10px'
                                                    }}>
                                                        <AlarmIcon type={alarm.state}/> {alarm.alarm_definition.name}
                                                    </Box>
                                                </TableCell>
                                                <TableCell><InsightButton ship={alarm.ship}/></TableCell>
                                                <TableCell>{moment(alarm.createdAt).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                                                <TableCell>{moment(alarm.timestamp).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                                                <TableCell align="right"><Button startIcon={<ArchiveIcon/>} onClick={() => setClosingAlarm(alarm)}>Close</Button></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                        <ConfirmDeleteDialog open={!!closingAlarm} onClose={() => { setClosingAlarm(false) }} onDelete={() => {closeAlarm()}} deleteText="Close">
                            <p>
                                You are about to close alarm <b>{closingAlarm ? closingAlarm.alarm_definition.name : ""}</b> for ship <b>{closingAlarm ? closingAlarm.ship.name : ""}</b><br />
                            </p>
                            <p>
                                Are you sure?
                            </p>
                        </ConfirmDeleteDialog>
                    </React.Fragment>
                );
            }
        }
    }
    return <Grid container spacing={24}>
        <Grid item xs={12} sm={12}>
            <AlarmListCard />
        </Grid>
    </Grid>
}