/**
 * Created by Robin on 04/01/2023.
 */

import React, {useContext, useEffect, useState} from "react";
import { Link, useParams, useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CreateIcon from '@mui/icons-material/Create';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';

import APIContext from './../context/APIContext.js';

import InvalidResource from './../components/InvalidResource.js';

import ConfirmDeleteDialog from './../components/ConfirmDeleteDialog.js';


function UserView(props) {
    const API = useContext(APIContext);
    const [user, setUser] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const navigate = useNavigate();
    let {id} = useParams();

    useEffect(() => {
        API.getUser(id).then((userData) => {
            setUser(userData);
        });

    }, [id, API]);

    const deleteUser = async () => {
        if (await API.deleteUser(user.id)) {
            navigate(-1);
        } else {
            alert('Failed to delete user');
        }
    }

    if (user === false) {
        return (
            <InvalidResource type="user" id={id}/>
        )
    } else if (user === null) {
        return <p>Loading...</p>;
    } else {
        return (
            <React.Fragment>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{marginBottom: '20px'}}
                    spacing="10px"
                >
                    <Grid item>
                        <IconButton size="small" variant="text" onClick={() => navigate(-1)}>
                            <ArrowBackIcon/>
                        </IconButton >
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <Typography variant="h5" component="h2">
                                <b>User:</b> {user.displayName}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Card>
                    <CardHeader title={
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item>
                                <Typography variant="h6">
                                    User information
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Button variant="contained" startIcon={<CreateIcon />} component={Link} to={'/user/' + user.id + '/edit'}>
                                            Edit
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" startIcon={<DeleteIcon />}
                                                onClick={() => setShowDeleteDialog(true)} color="error">
                                            Delete
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }/>
                    <CardContent>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell variant="head">
                                        Name
                                    </TableCell>
                                    <TableCell>
                                        {user.displayName}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head">
                                        E-mail
                                    </TableCell>
                                    <TableCell>
                                        {user.email}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head">
                                        Phone
                                    </TableCell>
                                    <TableCell>
                                        {user.phone}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head">
                                        Notifications
                                    </TableCell>
                                    <TableCell>
                                        {user.allowAlarmNotifications === 1 ? <CheckIcon /> : <ClearIcon />}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head">
                                        Admin
                                    </TableCell>
                                    <TableCell>
                                        {user.admin === 1 ? <CheckIcon /> : <ClearIcon />}
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell variant="head">
                                        Active
                                    </TableCell>
                                    <TableCell>
                                        {user.active === 1 ? <CheckIcon /> : <ClearIcon />}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
                <ConfirmDeleteDialog open={showDeleteDialog} onClose={() => { setShowDeleteDialog(false) }} onDelete={deleteUser}>
                    <p>
                        You are about to delete the user <b>{user.displayName}</b><br />
                        You will lose all tickets, ticket actions, ... related to this user!
                    </p>
                    <p>
                        Are you sure?
                    </p>
                </ConfirmDeleteDialog>
            </React.Fragment>
        );
    }
}

export default UserView;