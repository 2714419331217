/**
 * Created by Robin on 06/01/2023.
 */

import React, {useState} from "react";

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function PasswordField(props) {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <TextField type={showPassword ? 'text' : 'password'} InputProps={{endAdornment:
            <InputAdornment position="end">
                <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>
        }} {...props}/>
    )
}

export default PasswordField;