import {useContext, useState, useEffect} from "react";

import Cookies from "js-cookie";

import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import APIContext from "../context/APIContext.js";


function SignIn() {
    const API = useContext(APIContext);

    /*
    enum authState {
        'AWAITING_INPUT',
        'AWAITING_REQUEST',
        'INVALID',
    }
     */
    // We do not include SUCCESS because we are on another page in this case
    const [authState, setAuthState] = useState('AWAITING_INPUT');
    const [rememberMe, setRememberMe] = useState(false);

    useEffect(() => {
        let cookieEmail = Cookies.get('email');
        let cookiePassword = Cookies.get('password');
        let cookieRememberMe = Cookies.get('rememberme') !== undefined;

        if (cookieEmail !== undefined && cookiePassword !== undefined) {
            //Cookie authentication
            setAuthState('AWAITING_REQUEST');
            API.authenticate(cookieRememberMe, cookieEmail, cookiePassword).then(() => {
                setAuthState('AWAITING_INPUT');
            });
        }
    }, [API]);

    const doAuth = async () => {
        setAuthState('AWAITING_REQUEST');
        let success = await API.authenticate(rememberMe);

        if (!success) {
            setAuthState('INVALID');

            setTimeout(() => {
                setAuthState('AWAITING_INPUT');
            }, 1500);
        } else {
            setAuthState('AWAITING_INPUT');
        }
    };

    const LoginButton = function(props) {
        let text = "Sign In";
        let color = undefined;
        let loading = false;

        if (authState === 'INVALID') {
            text = "Invalid credentials";
            color = "error";
        } else if (authState === 'AWAITING_REQUEST') {
            text = "";
            loading = true;
        }

        return (<LoadingButton
            loading={loading}
            type="submit"
            fullWidth
            variant="contained"
            onClick={() => doAuth()}
            sx={{ mt: 3, mb: 2 }}
            color={color}
        >
            {text}
        </LoadingButton>);
    };

    return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="E-mail"
                            name="email"
                            autoComplete="off"
                            onChange={(event) => API.setEmail(event.target.value)}
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            onChange={(event) => API.setPassword(event.target.value)}
                            autoComplete="current-password"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={rememberMe} color="primary" onChange={() => {
                                setRememberMe(!rememberMe);
                            }}/>}
                            label="Remember me"
                        />
                        <LoginButton />
                    </Box>
                </Box>
            </Container>
    );
}

export default SignIn;