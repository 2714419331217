/**
 * Created by Robin on 25/07/2023.
 */

import React, { useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import screenfull from 'screenfull'

const Button = styled.button`
  background-color: white;
  width: 34px;
  height: 34px;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  cursor: pointer;
  text-align: center;
  padding: 0 4px;
  svg {
    color: rgba(0, 0, 0, 0.8) !important;
    height: 30px;
  }
  margin-top: 7px;
  position: relative;
  top: 70px;
  left: 10px;
  z-index: 9999;
`;

const FullscreenControl = ({ mapRef }) => {
    const [isFullscreen, setIsFullscreen] = useState(false);
    const onFullscreenChange = useCallback(
        () => setIsFullscreen(screenfull.isFullscreen),
        [],
    );

    useEffect(() => {
        screenfull.on('change', onFullscreenChange);
        return () => screenfull.off('change', onFullscreenChange)
    }, [onFullscreenChange]);

    const onClick = useCallback(() => {
        if (screenfull.isEnabled) {
            screenfull.toggle(mapRef.current)
        }
    }, [mapRef]);

    return (
        <Button
            onClick={onClick}
            title={isFullscreen ? 'Exit full screen' : 'Show full screen'}
        >
            {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </Button>
    )
}

export default FullscreenControl