/**
 * Created by Robin on 04/01/2023.
 */

import React from "react";

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import {Link} from "react-router-dom";

import SensorsIcon from '@mui/icons-material/Sensors';

function DeviceButton(props) {

    if (props.device === undefined || props.device === null || props.device.id === null) {
        return [];
    } else {
        return (
            <Tooltip
                title={<span><b>{props.device.name}</b><br />{props.device.ship !== null ? props.device.ship.name : ''}</span>}>
                <Button variant="text" style={{textTransform: 'none'}} startIcon={<SensorsIcon />} component={Link} to={"/device/" + props.device.id}>
                    {props.device.name}
                </Button>
            </Tooltip>
        );
    }
}

export default DeviceButton;