/**
 * Created by Robin on 24/07/2023.
 */

import React, {useContext, useEffect, useState} from "react";
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';

import APIContext from './../../context/APIContext.js';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import ChartTile from './ChartTile.js';

export default function InsightPage(props) {
    const API = useContext(APIContext);

    const {id} = useParams();
    const navigate = useNavigate();

    const [interval, setInterval] = useState('1h');
    const [date, setDate] = useState(moment().subtract(1, 'hour'));
    const [charts, setCharts] = useState([]);
    const [ship, setShip] = useState(false);

    useEffect(() => {
        API.getShip(id).then((ship) => {
            setShip(ship);
        });

        API.getShipCharts(id).then((charts) => {
           setCharts(charts);
        });
    }, [API, interval, date]);

    const decreaseTime = () => {
        console.log(interval);
        if (interval === "1h") {
            setDate(date.clone().subtract(30, "minutes"));
        } else if (interval === "1d") {
            setDate(date.clone().subtract(1, "days"));
        } else if (interval === "30d") {
            setDate(date.clone().subtract(30, "days"));
        }
    }

    const increaseTime = () => {
        if (interval === "1h") {
            setDate(date.clone().add(30, "minutes"));
        } else if (interval === "1d") {
            setDate(date.clone().add(1, "days"));
        } else if (interval === "30d") {
            setDate(date.clone().add(30, "days"));
        }
    }

    return (
        <React.Fragment>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        style={{marginBottom: '20px'}}
                        spacing="10px"
                    >
                        <Grid item>
                            <IconButton size="small" variant="text" onClick={() => navigate(-1)}>
                                <ArrowBackIcon/>
                            </IconButton >
                        </Grid>
                        <Grid item>
                            <Grid
                                container
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <Typography variant="h5" component="h2">
                                    <b>Insights:</b> {ship ? ship.name : ''}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>

                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Date Selection" />
                        <CardContent>
                            <Grid container justifyContent="space-between">
                                <Grid item
                                      xs={12} sm={6}
                                >
                                    <IconButton onClick={decreaseTime}>
                                        <KeyboardArrowLeftIcon />
                                    </IconButton>
                                    {
                                        interval === '1h' ?
                                                <MobileDateTimePicker
                                                    slotProps={{ textField: { size: 'small' } }}
                                                    value={date}
                                                    label="Date"
                                                    format="DD-MM-YYYY HH:mm"
                                                    ampm={false}
                                                    onAccept={(date) => {setDate(date)}}
                                                    disableFuture
                                                />
                                            :
                                                <DatePicker
                                                    slotProps={{ textField: { size: 'small' } }}
                                                    value={date}
                                                    label="Date"
                                                    format="DD-MM-YYYY"
                                                    onAccept={(date) => {setDate(date)}}
                                                    disableFuture
                                                />
                                    }
                                    <IconButton onClick={increaseTime}>
                                        <KeyboardArrowRightIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{textAlign: {xs: "left", sm: "right"}}}>
                                    <ButtonGroup variant="outlined" size="small">
                                        <Button variant={interval === '1h' ? "contained" : undefined} onClick={() => {
                                            setInterval("1h")
                                        }}>
                                            1 Hour
                                        </Button>
                                        <Button variant={interval === '1d' ? "contained" : undefined} onClick={() => {
                                            setInterval("1d");
                                            setDate(date.set({hour:0,minute:0,second:0,millisecond:0}));
                                        }}>
                                            1 Day
                                        </Button>
                                        <Button variant={interval === '30d' ? "contained" : undefined} onClick={() => {
                                            setInterval("30d");
                                            setDate(date.set({hour:0,minute:0,second:0,millisecond:0}));
                                        }}>
                                            30 Days
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {
                            charts.map((chart) => {
                                return (
                                    <Grid key={chart.id} item xs={12} md={6} lg={4}>
                                        <ChartTile key={chart.id} date={date} interval={interval} shipId={id} chart={chart} />
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}