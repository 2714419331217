/**
 * Created by Robin on 25/07/2023.
 */

import React, {useContext, useEffect, useState, useRef} from "react";

import moment from 'moment';

import { MapContainer, TileLayer, useMap, Marker, Popup, Polyline } from 'react-leaflet'
import FullscreenControl from './FullscreenControl.js';
import ShipMarker from './ShipMarker.js'

export default function MapChart(props) {
    const containerRef = useRef(null);
    const [lines, setLines] = useState([]);

    function FlyMapTo() {
        let latestValue = [0, 0];
        if (props.data && props.data.length > 0) {
            latestValue = props.data[props.data.length - 1][1];
        }

        const map = useMap();

        useEffect(() => {
            map.flyTo([latestValue.lat, latestValue.long]);
        }, [props.data]);

        return [];
    }

    useEffect(() => {
        let lines = [];

        if (props.data) {
            for (let i = 0; i < props.data.length - 1; i++) {
                const p1 = props.data[i][1];
                const p2 = props.data[i + 1][1];

                lines.push({
                    start: [p1.lat, p1.long],
                    end: [p2.lat, p2.long],
                });
            }
        }

        setLines(lines);
    }, [props.data]);

    if (props.data && props.data.length > 0) {
        const latestValue = props.data[props.data.length - 1][1];
        return (
            <div ref={containerRef} style={{height: '265px'}}>
                <MapContainer center={[latestValue.lat, latestValue.long]} zoom={14} scrollWheelZoom={true} style={{height: '100%'}}>
                    <TileLayer
                        //attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <ShipMarker position={[latestValue.lat, latestValue.long]}>
                        {moment(props.data[props.data.length - 1][0]).format("D MMM YYYY HH:mm:ss")}
                    </ShipMarker>
                    {
                        lines.map((line, index) => {
                            return <Polyline key={index} positions={[line.start, line.end]}/>
                        })
                    }
                    <FullscreenControl mapRef={containerRef}/>
                    <FlyMapTo />
                </MapContainer>
            </div>
        )
    } else {
        return [];
    }
}