/**
 * Created by Robin
 * Created on 08/03/2024
 */

import React, {useContext, useEffect, useState} from "react";
import { useParams, useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';

import APIContext from './../context/APIContext.js';

import InvalidResource from './../components/InvalidResource.js';
import PasswordField from "../components/PasswordField";
import UserContext from "../context/UserContext";


function UserChangePassword(props) {
    const API = useContext(APIContext);
    const User = useContext(UserContext);

    const [updatePending, setUpdatePending] = useState(false);

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword1, setNewPassword1] = useState("");
    const [newPassword2, setNewPassword2] = useState("");

    const navigate = useNavigate();

    const isValid = () => {
        if (currentPassword !== API.password) {
            return false;
        }

        if (newPassword1 !== newPassword2) {
            return false;
        }

        if (newPassword1.length < 8) {
            return false;
        }

        return true;
    };

    const updateUser = () => {
        const data = {
            password: newPassword1
        };

        setUpdatePending(true);

        API.setUser(User.id, data).then((res) => {
            if (res) {
                navigate("/");
                API.logoff();
            } else {
                alert('Failed to update the user');
                setUpdatePending(false);
            }
        })
    };

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                style={{marginBottom: '20px'}}
                spacing="10px"
            >
                <Grid item>
                    <IconButton size="small" variant="text" onClick={() => navigate(-1)}>
                        <ArrowBackIcon/>
                    </IconButton >
                </Grid>
                <Grid item>
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <Typography variant="h5" component="h2">
                            <b>Change password:</b> {User.displayName}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Card>
                <CardHeader title={
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h6">
                            Password information
                        </Typography>
                        <LoadingButton loading={updatePending} variant="contained" startIcon={<SaveIcon />}
                                       onClick={updateUser} disabled={!isValid()}>
                            Save
                        </LoadingButton>
                    </Grid>
                }/>
                <CardContent>
                    <Grid container spacing={{md: 4, xs: 0}}>
                        <Grid item xs={12} md={12}>
                            <PasswordField fullWidth label="Current Password" style={{margin: '10px 0px'}} variant="outlined" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)}/>
                            <PasswordField fullWidth label="New Password" style={{margin: '10px 0px'}} variant="outlined" value={newPassword1} onChange={(e) => setNewPassword1(e.target.value)}/>
                            <PasswordField fullWidth label="New Password (again)" style={{margin: '10px 0px'}} variant="outlined" value={newPassword2} onChange={(e) => setNewPassword2(e.target.value)}/>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </React.Fragment>
    );
}

export default UserChangePassword;