/**
 * Created by Robin on 04/01/2023.
 */

import React, {useContext, useEffect, useState, useCallback} from "react";

import Skeleton from '@mui/material/Skeleton';

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import APIContext from './../context/APIContext.js';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import AddIcon from '@mui/icons-material/Add';

import ListCardHeader from './../components/ListCardHeader.js';
import GroupButton from './../components/GroupButton.js';
import UserButton from './../components/UserButton.js';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

function GroupList(props) {
    const API = useContext(APIContext);
    const [groupCategories, setGroupCategories] = useState(false);

    const [showAddGroupCategory, setShowAddGroupCategory] = useState(false);
    const [showAddGroup, setShowAddGroup] = useState(false);

    const [categoryName, setCategoryName] = useState('');
    const [groupAddCategory, setGroupAddCategory] = useState(null);
    const [groupName, setGroupName] = useState('');


    const reloadGroupCategories = useCallback(() => {
        API.getGroupCategories().then((groupCategories) => {
            if (groupCategories !== false) {
                setGroupCategories(groupCategories);
            }
        });
    }, [API])

    useEffect(() => {
        reloadGroupCategories();
    }, [reloadGroupCategories]);

    const handleCloseAddGroupCategory = () => {
        setShowAddGroupCategory(false);
        setCategoryName('');
    };

    const handleAddGroupCategory = () => {
        API.createGroupCategory({
            name: categoryName
        }).then((res) => {
            if (res) {
                handleCloseAddGroupCategory();
                reloadGroupCategories();
            } else {
                handleCloseAddGroupCategory();
                alert('Failed to add group category');
            }
        })
    };

    const handleCloseAddGroup = () => {
        setShowAddGroup(false);
        setGroupName('');
    };

    const handleAddGroup = (categoryId) => {
        API.createGroup(groupAddCategory.id, {
            name: groupName
        }).then((res) => {
            if (res) {
                handleCloseAddGroup();
                reloadGroupCategories();
            } else {
                handleCloseAddGroup();
                alert('Failed to add group');
            }
        })
    };

    const handleDeleteGroupCategory = (categoryId) => {
        API.deleteGroupCategory(categoryId).then((res) => {
            if (!res) {
                alert('Failed to delete group category');
            }

            reloadGroupCategories();
        })
    };

    const GroupCategoryCard = (props) => {
        return (
            <Card>
                <ListCardHeader title={props.category.name} createCallback={() => {setShowAddGroup(true); setGroupAddCategory(props.category)}} createLabel="Add group" deleteCallback={props.category.groups.length > 0 ? undefined : () => handleDeleteGroupCategory(props.category.id)} deleteLabel="Delete category"/>
                <CardContent sx={{
                    paddingTop: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingBottom: '0px !important'
                }}>
                    {(props.category.groups.length > 0) ?
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Group name</TableCell>
                                    <TableCell>Ship count</TableCell>
                                    <TableCell>User count</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.category.groups.map((group) => (
                                    <TableRow
                                        key={group.id}

                                    >
                                        <TableCell><GroupButton group={group}/></TableCell>
                                        <TableCell>{group.shipCount}</TableCell>
                                        <TableCell>{group.userCount}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        :
                        <CardContent><b>No groups in this category</b></CardContent>
                    }
                </CardContent>
            </Card>
        );
    };

    return (
        <Grid container spacing={4}>
            <Dialog open={showAddGroupCategory} onClose={handleCloseAddGroupCategory}>
                <DialogTitle>
                    Add group category
                </DialogTitle>
                <DialogContent>
                    Please fill in the new group category name:
                    <TextField fullWidth label="Category name" style={{margin: '20px 0px'}} variant="outlined" value={categoryName} onChange={(e) => setCategoryName(e.target.value)}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddGroupCategory}>Cancel</Button>
                    <Button onClick={handleAddGroupCategory}>Add</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={showAddGroup} onClose={handleCloseAddGroup}>
                <DialogTitle>
                    Add group to {groupAddCategory?.name}
                </DialogTitle>
                <DialogContent>
                    Please fill in the new group name:
                    <TextField fullWidth label="Group name" style={{margin: '20px 0px'}} variant="outlined" value={groupName} onChange={(e) => setGroupName(e.target.value)}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddGroup}>Cancel</Button>
                    <Button onClick={handleAddGroup}>Add</Button>
                </DialogActions>
            </Dialog>

            <Grid item xs={12} sm={12}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h4">Group categories</Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" startIcon={<AddIcon />} onClick={() => setShowAddGroupCategory(true)}>
                            Create category
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            {
                groupCategories && groupCategories.map((category) => {
                    return (
                    <Grid item xs={12} sm={12} key={category.id}>
                        <GroupCategoryCard category={category}/>
                    </Grid>);
                })
            }
        </Grid>
    );
}

export default GroupList;