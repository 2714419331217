/**
 * Created by Robin on 18/07/2023.
 */

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import InsightButton from './../../../components/InsightButton.js';
import SparkLine from './SparkLine.js';
import AlarmDisplay from './AlarmDisplay.js';
import {useContext, useEffect, useState} from "react";
import APIContext from "../../../context/APIContext";

export default function ShipRow(props) {
    const API = useContext(APIContext);
    const [charts, setCharts] = useState(false);
    const [speedChart, setSpeedChart] = useState(null);
    const [fuelChart, setFuelChart] = useState(null);

    useEffect(() => {
        API.getShipCharts(props.ship.id).then((charts) => {
            console.log(charts);
            setCharts(charts);
        });
    }, [API]);

    useEffect(() => {
        if (Array.isArray(charts)) {
            setSpeedChart(GetSpeedChart(charts));
            setFuelChart(GetFuelChart(charts));
        }

    }, [charts]);

    const GetSpeedChart = (charts) => {
        return charts.find((c) => {
           return c.speedInsight;
        });
    }

    const GetFuelChart = (charts) => {
        return charts.find((c) => {
            return c.fuelInsight;
        });
    }

    return (
        <TableRow>
            <TableCell><InsightButton ship={props.ship} /></TableCell>
            <TableCell>{props.ship.mmsi}</TableCell>
            <TableCell>
                <AlarmDisplay ship={props.ship} updateInterval={30} />
            </TableCell>
            {
                //<TableCell></TableCell>
            }
            <TableCell style={{
                maxWidth: '80px'
            }}>
                {
                    speedChart &&
                        <SparkLine ship={props.ship} monitor={speedChart.metric.monitor.displayName} metric={speedChart.metric.name} height={30} minimumTopValue={speedChart.expectedMax} unit={speedChart.metric.info.unit} updateInterval={30}/>
                }
            </TableCell>
            <TableCell style={{
                maxWidth: '80px'
            }}>
                {
                    fuelChart &&
                        <SparkLine ship={props.ship} monitor={fuelChart.metric.monitor.displayName} metric={fuelChart.metric.name} height={30} minimumTopValue={fuelChart.expectedMax} unit={fuelChart.metric.info.unit} updateInterval={30}/>
                }
            </TableCell>
        </TableRow>
    )
}