/**
 * Created by Robin on 04/01/2023.
 */

import React, {useContext, useEffect, useState} from "react";
import { Link, useParams, useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';

import APIContext from './../context/APIContext.js';

import InvalidResource from './../components/InvalidResource.js';


import UserButton from './../components/UserButton.js';
import ConfirmDeleteDialog from './../components/ConfirmDeleteDialog.js';


function ShipView(props) {
    const API = useContext(APIContext);
    const [ship, setShip] = useState(null);
    const [monitors, setMonitors] = useState(null);
    const [charts, setCharts] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const navigate = useNavigate();
    let {id} = useParams();

    useEffect(() => {
        API.getShip(id).then((shipData) => {
            setShip(shipData);
        });

        API.getShipMonitors(id).then((monitors) => {
            setMonitors(monitors);
        });

        API.getShipCharts(id).then((charts) => {
            setCharts(charts);
        });
    }, [id, API]);

    const deleteShip = async () => {
        if (await API.deleteShip(ship.id)) {
            navigate(-1);
        } else {
            alert('Failed to delete ship');
        }
    };

    if (ship === false) {
        return (
            <InvalidResource type="ship" id={id}/>
        )
    } else if (ship === null) {
        return <p>Loading...</p>;
    } else {
        return (
            <React.Fragment>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{marginBottom: '20px'}}
                    spacing="10px"
                >
                    <Grid item>
                        <IconButton size="small" variant="text" onClick={() => navigate(-1)}>
                            <ArrowBackIcon/>
                        </IconButton >
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <Typography variant="h5" component="h2">
                                <b>Ship:</b> {ship.name}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title={
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Typography variant="h6">
                                            Ship information
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <Button variant="contained" startIcon={<CreateIcon />} component={Link} to={'/ship/' + ship.id + '/edit'}>
                                                    Edit
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="contained" startIcon={<DeleteIcon />}
                                                        onClick={() => setShowDeleteDialog(true)} color="error">
                                                    Delete
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }/>
                            <CardContent>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell variant="head">
                                                Name
                                            </TableCell>
                                            <TableCell>
                                                {ship.name}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell variant="head">
                                                MMSI
                                            </TableCell>
                                            <TableCell>
                                                {ship.mmsi}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Card>
                            <CardHeader title={<Typography variant="h6">
                                    Safety contact
                                </Typography>} />
                            <CardContent>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell variant="head">
                                                Name
                                            </TableCell>
                                            <TableCell>
                                                <UserButton user={ship.safetyUser} />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant="head">
                                                E-mail
                                            </TableCell>
                                            <TableCell>
                                                {ship.safetyUser.email}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell variant="head">
                                                Phone
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Card>
                            <CardHeader title={<Typography variant="h6">
                                Technical contact
                            </Typography>} />
                            <CardContent>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell variant="head">
                                                Name
                                            </TableCell>
                                            <TableCell>
                                                <UserButton user={ship.technicalUser} />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant="head">
                                                E-mail
                                            </TableCell>
                                            <TableCell>
                                                {ship.technicalUser.email}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell variant="head">
                                                Phone
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Card>
                            <CardHeader title={<Typography variant="h6">
                                Monitors
                            </Typography>} />
                            {
                                !monitors && <p>Loading...</p>
                            }
                            {
                                monitors && monitors.length === 0 &&
                                    <p>No monitors defined</p>
                            }
                            {
                                monitors && monitors.length > 0 &&
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Parser</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                monitors.map((monitor) => {
                                                    return (
                                                        <TableRow key={monitor.id}>
                                                            <TableCell>
                                                                {monitor.displayName}
                                                            </TableCell>
                                                            <TableCell>
                                                                {monitor.parser}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                        </Card>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Card>
                            <CardHeader title={<Typography variant="h6">
                                Charts
                            </Typography>} />
                            {
                                !charts && <p>Loading...</p>
                            }
                            {
                                charts && charts.length === 0 &&
                                <p>No charts defined</p>
                            }
                            {
                                charts && charts.length > 0 &&
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                charts.map((chart) => {
                                                    return (
                                                        <TableRow key={chart.id}>
                                                            <TableCell>
                                                                {chart.caption}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                        </Card>
                    </Grid>
                </Grid>
                <ConfirmDeleteDialog open={showDeleteDialog} onClose={() => { setShowDeleteDialog(false) }} onDelete={deleteShip}>
                    <p>
                        You are about to delete the ship <b>{ship.name}</b><br />
                        You will lose all metrics collected for this ship!
                    </p>
                    <p>
                        Are you sure?
                    </p>
                </ConfirmDeleteDialog>
            </React.Fragment>
        );
    }
}

export default ShipView;