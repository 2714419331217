/**
 * Created by Robin on 04/01/2023.
 */

import React, {useContext, useState, useEffect, useCallback} from "react";
import { useNavigate, useParams } from 'react-router-dom';

import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

import APIContext from './../context/APIContext.js';

import ShipSelector from './../components/ShipSelector.js';
import GroupSelector from './../components/GroupSelector.js';
import MetricNameSelector from './../components/MetricNameSelector.js';

import ConfirmDeleteDialog from './../components/ConfirmDeleteDialog.js';

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';

TimeAgo.addDefaultLocale(en);

function AlarmDefinitionCreate(props) {
    const API = useContext(APIContext);
    const [updatePending, setUpdatePending] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [alarmDefinition, setAlarmDefinition] = useState(null);

    const [name, setName] = useState('');
    const [targetType, setTargetType] = useState('SHIP');
    const [shipId, setShipId] = useState(null);
    const [groupId, setGroupId] = useState(null);
    const [monitorName, setMonitorName] = useState(null);
    const [metricName, setMetricName] = useState(null);
    const [period, setPeriod] = useState(10);
    const [measurementType, setMeasurementType] = useState('AVG');
    const [warningEnabled, setWarningEnabled] = useState(false);
    const [criticalEnabled, setCriticalEnabled] = useState(false);
    const [comparisonOpWarning, setComparisonOpWarning] = useState('GTEQ');
    const [comparisonOpCritical, setComparisonOpCritical] = useState('GTEQ');
    const [valueWarning, setValueWarning] = useState(null);
    const [valueCritical, setValueCritical] = useState(null);

    const navigate = useNavigate();
    let {id} = useParams();

    const reloadDefinition = useCallback(() => {
        console.log("Reload");
        API.getAlarmDefinition(id).then((definitionData) => {
            console.log(definitionData);
            setAlarmDefinition(definitionData);

            setName(definitionData.name);
            if (definitionData.ship.id === null) {
                setTargetType('GROUP');
            } else {
                setTargetType('SHIP');
            }
            setShipId(definitionData.ship.id);
            setGroupId(definitionData.group.id);
            setMonitorName(definitionData.monitor_name);
            setMetricName(definitionData.metric_name);
            setPeriod(definitionData.interval_min);
            setMeasurementType(definitionData.measurement_type);
            setWarningEnabled(definitionData.warning_enabled);
            setCriticalEnabled(definitionData.critical_enabled);
            setComparisonOpWarning(definitionData.warning_comparison);
            setComparisonOpCritical(definitionData.critical_comparison);
            setValueWarning(definitionData.warning_value);
            setValueCritical(definitionData.critical_value);
        });
    },[API, id]);

    useEffect(() => {
        reloadDefinition();
    }, [reloadDefinition]);

    const updateAlarmDefinition = () => {
        const data = {
            name: name,
            interval_min: period,
            measurement_type: measurementType,
            warning_enabled: warningEnabled,
            critical_enabled: criticalEnabled,
            warning_comparison: comparisonOpWarning,
            critical_comparison: comparisonOpCritical,
            warning_value: valueWarning,
            critical_value: valueCritical,
            monitor_name: monitorName,
            metric_name: metricName
        };

        if (targetType === 'SHIP') {
            data.shipId = shipId;
            data.groupId = null;
        } else {
            data.shipId = null;
            data.groupId = groupId
        }

        setUpdatePending(true);

        API.setAlarmDefinition(id, data).then((res) => {
            if (res) {
                navigate(-1);
            } else {
                alert("Failed to update alarm definition!");
                setUpdatePending(false);
            }
        })
    };


    const deleteAlarmDefinition = () => {
        API.deleteAlarmDefinition(alarmDefinition.id).then(res => {
            if (res) {
                navigate(-1);
            } else {
                alert("Failed to delete alarm definition!");
                setUpdatePending(false);
            }
        });
    };

    const isInputValid = () => {
        if (name.length < 3) {
            return false;
        }

        if (monitorName === null || metricName === null) {
            return false;
        }

        if (targetType === 'SHIP' && shipId === null) {
            return false;
        }

        if (targetType === 'GROUP' && groupId === null) {
            return false;
        }

        return true;
    };

    const parseNumber = (num) => {
        if (num.length === 0) {
            return null;
        }

        const v = parseInt(num);

        if (isNaN(v)) {
            return null;
        }

        return v;
    }

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                style={{marginBottom: '20px'}}
                spacing="10px"
            >
                <Grid item>
                    <IconButton size="small" variant="text" onClick={() => navigate(-1)}>
                        <ArrowBackIcon/>
                    </IconButton >
                </Grid>
                <Grid item>
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <Typography variant="h5" component="h2">
                            <b>Edit alarm definition</b>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            title="Alarm definition details"
                            action={
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={() => { setShowDeleteDialog(true) }}>Delete</Button>
                                    </Grid>
                                    <Grid item>
                                        <LoadingButton loading={updatePending} variant="contained" startIcon={<SaveIcon />}
                                                       onClick={updateAlarmDefinition} disabled={!isInputValid()}>
                                            Save
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            }
                        />
                        <CardContent>
                            <Grid container spacing={{md: 2, xs: 0}}>
                                <Grid item xs={12} md={12}>
                                    <TextField fullWidth label="Alarm name" style={{margin: '10px 0px'}} variant="outlined" value={name || ''} onChange={(e) => setName(e.target.value)}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField fullWidth select style={{margin: '10px 0px'}} value={targetType} variant="outlined" label="Target type" onChange={(e) => setTargetType(e.target.value)}>
                                        <MenuItem value="SHIP">Single Ship</MenuItem>
                                        <MenuItem value="GROUP">Group</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {targetType === 'SHIP' && <ShipSelector value={shipId} label="Target ship" onChange={(e) => setShipId(e.target.value)}/>}
                                    {targetType === 'GROUP' && <GroupSelector value={groupId} label="Target group" onChange={(e) => setGroupId(e.target.value)}/>}
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    {targetType === 'SHIP' && <MetricNameSelector key="monitor-ship-selector" monitor={monitorName} metric={metricName} onMonitorChange={(v) => setMonitorName(v)} onMetricChange={(v) => setMetricName(v)} shipId={shipId} />}
                                    {targetType === 'GROUP' && <MetricNameSelector key="monitor-group-selector" monitor={monitorName} metric={metricName} onMonitorChange={(v) => setMonitorName(v)} onMetricChange={(v) => setMetricName(v)} groupId={groupId} />}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} fullWidth label="Period (minutes)" style={{margin: '10px 0px'}} variant="outlined" value={period} onChange={(e) => setPeriod(parseInt(e.target.value) || 0)}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField fullWidth select style={{margin: '10px 0px'}} value={measurementType || ''} variant="outlined" label="Aggregation" onChange={(e) => setMeasurementType(e.target.value)}>
                                        <MenuItem value="AVG">Average</MenuItem>
                                        <MenuItem value="MINMAX_UNIT">Min/Max difference (Unit)</MenuItem>
                                        <MenuItem value="MINMAX_PERCENT">Min/Max difference (Percentage)</MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Card>
                        <CardHeader title="Warning" />
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <FormGroup style={{margin: "19px 0px"}}>
                                        <FormControlLabel control={<Switch checked={!!warningEnabled} onChange={(e) => {setWarningEnabled(!warningEnabled)}} />} label="Enabled" />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField disabled={!warningEnabled} fullWidth select style={{margin: '10px 0px'}} value={comparisonOpWarning || ''} variant="outlined" label="Operator" onChange={(e) => setComparisonOpWarning(e.target.value)}>
                                        <MenuItem value="GTEQ">{'>='}</MenuItem>
                                        <MenuItem value="LTEQ">{'<='}</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField disabled={!warningEnabled} fullWidth label="Treshold" style={{margin: '10px 0px'}} variant="outlined" value={valueWarning === null ? '' : valueWarning} onChange={(e) => setValueWarning(parseNumber(e.target.value))}/>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Card>
                        <CardHeader title="Critical" />
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <FormGroup style={{margin: "19px 0px"}}>
                                        <FormControlLabel control={<Switch checked={!!criticalEnabled} onChange={(e) => {setCriticalEnabled(!criticalEnabled)}} />} label="Enabled" />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField disabled={!criticalEnabled} fullWidth select style={{margin: '10px 0px'}} value={comparisonOpCritical || ''} variant="outlined" label="Operator" onChange={(e) => setComparisonOpCritical(e.target.value)}>
                                        <MenuItem value="GTEQ">{'>='}</MenuItem>
                                        <MenuItem value="LTEQ">{'<='}</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField disabled={!criticalEnabled} fullWidth label="Treshold" style={{margin: '10px 0px'}} variant="outlined" value={valueCritical === null ? '' : valueCritical} onChange={(e) => setValueCritical(parseNumber(e.target.value))}/>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <ConfirmDeleteDialog open={showDeleteDialog} onClose={() => { setShowDeleteDialog(false) }} onDelete={deleteAlarmDefinition}>
                <p>
                    You are about to delete the alarm definition <b>{alarmDefinition?.name}</b>
                </p>
                <p>
                    Are you sure?
                </p>
            </ConfirmDeleteDialog>
        </React.Fragment>
    );
}

export default AlarmDefinitionCreate;