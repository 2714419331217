/**
 * Created by Robin on 04/01/2023.
 */

import React from "react";

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import {Link} from "react-router-dom";

import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';

function AlarmDefinitionButton(props) {

    if (props.alarmDefinition === undefined || props.alarmDefinition === null || props.alarmDefinition.id === null) {
        return [];
    } else {
        return (
            <Button variant="text" style={{textTransform: 'none'}} startIcon={<NotificationImportantIcon />} component={Link} to={"/alarmdefinition/" + props.alarmDefinition.id}>
                {props.alarmDefinition.name}
            </Button>
        );
    }
}

export default AlarmDefinitionButton;