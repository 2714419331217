/**
 * For internal use only
 * Never share this code outside the organization
 * Created by Robin
 * Created on 12/03/2024
 */
import TextField from "@mui/material/TextField";
import React from "react";

export default function TextInput(props) {
    return (
        <TextField fullWidth style={{margin: '10px 0px'}} value={props.data[props.keyName] !== undefined ? props.data[props.keyName] : ''} label={props.parameter.displayName}
                   onChange={(e) => {
                       props.setData(props.keyName, e.target.value);
                   }}
                   onFocus={event => {
                       event.target.select();
                   }}
        />
    );
}