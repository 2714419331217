/**
 * Created by Robin on 18/12/2022.
 */

import * as React from "react";
import {useNavigate} from "react-router-dom";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

function InvalidResource(props) {
    const navigate = useNavigate();

    return (
        <Grid container justifyContent="center">
            <div>
                <Typography variant="h4" component="h2" style={{textAlign: 'center'}} color="string">
                    Oops, something went wrong!
                </Typography>
                <p style={{textAlign: 'center'}}>
                    We are unable to load the specified resource ({props.type}:{props.id})
                </p>
                <div style={{textAlign: 'center'}}>
                    <SentimentVeryDissatisfiedIcon color="error" fontSize="large"/>
                </div>
                <p style={{textAlign: 'center'}}>
                    This error can occur if on of the following happens:
                </p>
                <ul style={{textAlign: 'left'}}>
                    <li>You do not have permission to access the resource</li>
                    <li>The resource does not exist or no longer exists</li>
                    <li>Your internet connection is unstable</li>
                </ul>

                <Button style={{width: '100%'}} variant="contained" onClick={() => navigate(-1)} endIcon={<ArrowBackIcon />}>Go back</Button>
            </div>
        </Grid>
    );
}

export default InvalidResource;