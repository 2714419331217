/**
 * Created by Robin on 26/07/2023.
 */

import React, {useContext, useEffect, useState} from "react";

import TextField from '@mui/material/TextField';

export default function IntegerInput(props) {
    const isNumericRegex = new RegExp('^[0-9]+$');

    return (
          <TextField fullWidth style={{margin: '10px 0px'}} value={props.data[props.keyName] !== undefined ? props.data[props.keyName].toString() : ''} label={props.parameter.displayName}
             onChange={(e) => {
                  if (isNumericRegex.test(e.target.value)) {
                      props.setData(props.keyName, parseInt(e.target.value));
                  }
              }}
             onFocus={event => {
                 event.target.select();
             }}
          />
    );
}