/**
 * Created by Robin on 14/04/2023.
 */

import React, {useContext, useEffect, useState} from "react";

import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'
import L from 'leaflet';

import Skeleton from '@mui/material/Skeleton';

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";

import APIContext from './../context/APIContext.js';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import InsightButton from './../components/InsightButton.js';

import SparkLine from '../components/SparkLine.js';

import ShipMapMarker from './../components/ShipMapMarker.js';

import LightGreenCircle from '../img/light-green-circle.png';
import DarkGreenCircle from '../img/dark-green-circle.png';
import OrangeCircle from '../img/orange-circle.png';
import RedCircle from '../img/red-circle.png';

function MapView(props) {
    const [ships, setShips] = useState(null);
    const [markers, setMarkers] = useState(null);
    const API = useContext(APIContext);

    const myIcon = new L.Icon({
        iconUrl: LightGreenCircle,
        iconRetinaUrl: LightGreenCircle,
        popupAnchor:  [-0, -0],
        iconSize: [9, 9],
    });

    useEffect(() => {
        API.getShips().then((ships) => {
            setShips(ships);
        });

        const interval = setInterval(() => {
            API.getShips().then((ships) => {
                setShips(ships);
            })
        }, 30000);
        return () => {
            clearInterval(interval);
        }
    }, []);

    useEffect(() => {
        updatePositions();
    }, [ships]);

    const updatePositions = async () => {
        let markers = [];
        if (Array.isArray(ships)) {
            let promises = [];
            for (const ship of ships) {
                promises.push(new Promise((resolve) => {
                    API.getDataLatest(ship.id, 'GPS Data', 'location').then((data) => {
                        if (data) {
                            const pos = data.value;
                            markers.push(
                                <ShipMapMarker key={ship.id} ship={ship} timestamp={data.timestamp} position={[pos.lat, pos.long]}>
                                    {ship.name}
                                </ShipMapMarker>
                            );
                        }
                        resolve();
                    })
                }));

                await Promise.all(promises);
            }
        }
        setMarkers(markers);
    };

    return (
        <Card style={{height: 'calc(100vh - 176px)'}}>
            <MapContainer center={[51.260, 5.922]} zoom={6} scrollWheelZoom={true} style={{height: '100%'}}>
                <TileLayer
                    //attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {markers}


                {/*
                <Marker position={[51.265389, 4.636033]} icon={myIcon} eventHandlers={{
                    mouseover: function(e) {
                        this.openPopup();
                    },
                    mouseout: function(e) {
                        this.closePopup();
                    }
                }}>
                    <Popup>
                        A pretty CSS3 popup. <br /> Easily customizable.
                    </Popup>
                </Marker>
                */}
            </MapContainer>
        </Card>
    );
}

export default MapView