import './App.css';

import AppRouter from './AppRouter.js';

import API from './services/API.js';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import User from "./services/User";

function App() {
    return (
            <API>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <AppRouter/>
                </LocalizationProvider>
            </API>
    );
}

export default App;
