/**
 * Created by Robin on 17/12/2022.
 */

import React, {useContext} from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import APIContext from './context/APIContext.js';

import Authenticate from './pages/Authenticate.js';
import AuthenticatedPageWrapper from './pages/AuthenticatedPageWrapper.js';

import Dashboard from './pages/Dashboard';

import TicketList from './pages/TicketList.js';
import TicketView from './pages/TicketView.js';
import TicketCreate from './pages/TicketCreate.js';

import DeviceList from './pages/DeviceList.js';
import DeviceView from './pages/DeviceView.js';
import DeviceCreate from './pages/DeviceCreate.js';

import ShipList from './pages/ShipList.js';
import ShipView from './pages/ShipView.js';
import ShipCreate from './pages/ShipCreate.js';
import ShipEdit from './pages/ShipEdit';

import UserList from './pages/UserList.js';
import UserView from './pages/UserView.js';
import UserCreate from './pages/UserCreate.js';
import UserEdit from './pages/UserEdit.js';

/*
import MetricList from './pages/MetricList.js';
import MetricView from './pages/MetricView.js';
import MetricCreate from './pages/MetricCreate.js';
*/

import GroupList from './pages/GroupList.js';
import GroupEdit from './pages/GroupEdit.js';

import MapView from './pages/MapView.js';

import AlarmDefinitionList from './pages/AlarmDefinitionList.js';
import AlarmDefinitionCreate from './pages/AlarmDefinitionCreate.js';
import AlarmDefinitionEdit from './pages/AlarmDefinitionEdit.js';

import InsightOverview from './pages/InsightOverview';
import InsightPage from './pages/InsightPage';
import DefaultTankerInsight from './pages/insights/DefaultTankerInsight.js';

import MonitorAdd from './pages/MonitorAdd';
import MonitorUpdate from './pages/MonitorUpdate';
import VolumeMapList from "./pages/VolumeMapList";
import VolumeMapCreate from "./pages/VolumeMapCreate";
import VolumeMapEdit from "./pages/VolumeMapEdit";
import ChartAdd from "./pages/ChartAdd";
import ChartEdit from "./pages/ChartEdit";
import User from "./services/User";
import AlarmList from "./pages/AlarmList";
import UserChangePassword from "./pages/UserChangePassword";

const router = createBrowserRouter([
    {
        path: "/",
        element: <AuthenticatedPageWrapper />,
        children: [
            {
                path: "/",
                element: <Dashboard />
            },
            {
                path: "password/",
                element: <UserChangePassword />
            },
            {
                path: "map/",
                element: <MapView />
            },
            {
                path: "ticket/",
                element: <TicketList />
            },
            {
                path: "ticket/create",
                element: <TicketCreate />
            },
            {
                path: "ticket/:id",
                element: <TicketView />
            },
            {
                path: "device/",
                element: <DeviceList />
            },
            {
                path: "device/create",
                element: <DeviceCreate />
            },
            {
                path: "device/:id",
                element: <DeviceView />
            },
            {
                path: "ship/",
                element: <ShipList />
            },
            {
                path: "ship/create",
                element: <ShipCreate />
            },
            {
                path: "ship/:id",
                element: <ShipView />
            },
            {
                path: "ship/:id/edit",
                element: <ShipEdit />
            },
            {
                path: "ship/:id/monitor/add",
                element: <MonitorAdd />
            },
            {
                path: "ship/:id/monitor/:monitorId",
                element: <MonitorUpdate />
            },
            {
                path: "ship/:id/chart/add",
                element: <ChartAdd />
            },
            {
                path: "ship/:shipId/chart/:chartId",
                element: <ChartEdit />
            },
            {
                path: "user/",
                element: <UserList />
            },
            {
                path: "user/create",
                element: <UserCreate />
            },
            {
                path: "user/:id",
                element: <UserView />
            },
            {
                path: "user/:id/edit",
                element: <UserEdit />
            },
            {
                path: "group/",
                element: <GroupList />
            },
            {
                path: "group/:id",
                element: <GroupEdit />
            },
            {
                path: "alarmdefinition/",
                element: <AlarmDefinitionList />
            },
            {
                path: "alarmdefinition/create",
                element: <AlarmDefinitionCreate />
            },
            {
                path: "alarmdefinition/:id",
                element: <AlarmDefinitionEdit />
            },
            {
                path: "insight/",
                element: <InsightOverview />
            },
            {
                path: "insight/:id",
                element: <InsightPage />
            },
            {
                path: "volume_map/",
                element: <VolumeMapList />
            },
            {
                path: "volume_map/create",
                element: <VolumeMapCreate />
            },
            {
                path: "volume_map/:id",
                element: <VolumeMapEdit />
            },
            {
                path: "alarm",
                element: <AlarmList />
            },
        ]
    }
]);

function AppRouter(props) {
    const API = useContext(APIContext);

    if (API.isLoggedIn()) {
        return (
            <User>
                <RouterProvider router={router} />
            </User>
        );
    } else {
        return <Authenticate />;
    }
}

export default AppRouter;