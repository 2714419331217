/**
 * Created by Robin on 18/07/2023.
 */

import React, {useContext, useEffect, useState} from "react";
import { useParams, useNavigate } from 'react-router-dom';

import APIContext from './../../context/APIContext.js';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import ShipRow from './ShipRow';

export default function InsightOverview(props) {
    const API = useContext(APIContext);
    const [ships, setShips] = useState(false);

    useEffect(() => {
        API.getShips().then((ships) => {
            if (ships !== false) {
                setShips(ships);
            }
        });
    }, [API]);

    if (ships) {
        return (
        <Card>
            <CardHeader title="Insights" />
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>MMSI</TableCell>
                            <TableCell>Alarms</TableCell>
                            {
                                //<TableCell>Tickets</TableCell>
                            }
                            <TableCell style={{
                                maxWidth: '100px'
                            }}>
                                Speed
                            </TableCell>
                            <TableCell>Fuel</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            ships.map((ship) => {
                                return (
                                    <ShipRow key={ship.id} ship={ship} />
                                )
                            })
                        }
                    </TableBody>
                </Table>
        </Card>
        );
    } else {
        return [];
    }
}