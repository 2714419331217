/**
 * Created by Robin on 04/01/2023.
 */

import React from "react";

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import {Link} from "react-router-dom";

import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';


function ShipButton(props) {

    if (props.ship === undefined || props.shop === null || props.ship.id === null) {
        return [];
    } else {
        return (
            <Tooltip
                title={<span><b>{props.ship.name}</b><br />{props.ship.mmsi !== null ? props.ship.mmsi : ''}</span>}>
                <Button variant="text" style={{textTransform: 'none'}} startIcon={<DirectionsBoatIcon />} component={Link} to={"/ship/" + props.ship.id}>
                    {props.ship.name}
                </Button>
            </Tooltip>
        );
    }
}

export default ShipButton;