/**
 * Created by Robin on 17/12/2022.
 */

import React, {useContext, useEffect, useState} from "react";

import Skeleton from '@mui/material/Skeleton';

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import APIContext from './../context/APIContext.js';
import TicketTable from './../components/TicketTable.js';
import ListCardHeader from './../components/ListCardHeader.js';

function TicketList(props) {
    const API = useContext(APIContext);
    const [tickets, setTickets] = useState(false);

    useEffect(() => {
        API.getTickets().then((tickets) => {
            if (tickets !== false) {
                setTickets(tickets);
            }
        });
    }, [API]);

    const TicketCard = () => {
        if (tickets === false) {
            return (
                <Card>
                    <CardContent>
                        <Skeleton variant="rounded" height={20} width="50%" animation="pulse"/>
                        <br />
                        <Skeleton variant="rounded" height={20} width="25%" animation="pulse"/>
                        <br />
                        <Skeleton variant="rounded" height={20} width="75%" animation="pulse"/>
                        <br />
                        <Skeleton variant="rounded" height={20} width="50%" animation="pulse"/>
                    </CardContent>
                </Card>
            );
        } else {
            return (
                <Card>
                    <ListCardHeader title="Ticket list" createLink="/ticket/create" />
                    <CardContent sx={{
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingBottom: '0px !important'
                    }}>
                        <TicketTable tickets={tickets}/>
                    </CardContent>
                </Card>
            );
        }
    }
    return <Grid container spacing={24}>
        <Grid item xs={12} sm={12}>
            <TicketCard />
        </Grid>
    </Grid>
}

export default TicketList;