/**
 * Created by Robin on 19/12/2022.
 */

import React, {useContext, useEffect, useState} from "react";

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';

import APIContext from './../context/APIContext.js';

function UserSelector(props) {
    const API = useContext(APIContext);
    const [users, setUsers] = useState(false);

    useEffect(() => {
        API.getUsers().then((userData) => {
            setUsers(userData);
        });
    }, [API]);

    if (users === false) {
        return <Skeleton variant="rounded" height={20} width="100%" animation="pulse"/>
    } else {
        let items = [];

        if (props.allowNull === true) {
            items.push(<MenuItem key={null} value={null}><i>(None)</i></MenuItem>);
        }

        for (const user of users) {
            items.push(<MenuItem key={user.id} value={user.id}>{user.displayName}</MenuItem>);
        }

        return (
            <TextField fullWidth select style={{margin: '10px 0px'}} value={props.value ?? ''} variant="outlined" label={props.label ? props.label : 'User'} onChange={props.onChange}>
                {items}
            </TextField>
        )
    }
}

export default UserSelector;