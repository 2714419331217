/**
 * Created by Robin on 26/07/2023.
 */

import React, {useContext, useEffect, useState} from "react";
import { useParams, useNavigate, Link } from 'react-router-dom';

import APIContext from '../../context/APIContext.js';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';

import DataField from './DataField'

export default function MonitorAdd(props) {
    const API = useContext(APIContext);
    const navigate = useNavigate();
    const [ship, setShip] = useState(null);
    const [parsers, setParsers] = useState([]);

    const [parser, setParser] = useState("");
    const [name, setName] = useState("");
    const [data, setData] = useState({});

    let {id} = useParams();

    useEffect(() => {
        API.getShip(id).then((ship) => {
            setShip(ship);
        });

        API.getAvailableParsers(id).then((parsers) => {
            setParsers(parsers);
        });
    }, [API]);

    const IsValid = () => {
        if (name.length < 3) {
            return false;
        }

        if (!parser || parser.length <= 0) {
            return false;
        }

        //TODO: Check parser parameters

        return true;
    };

    const AddMonitor = () => {
        API.createShipMonitor(id, name, parser, data).then((monitor) => {
            navigate(-1);
        });
    };

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                style={{marginBottom: '20px'}}
                spacing="10px"
            >
                <Grid item>
                    <IconButton size="small" variant="text" onClick={() => navigate(-1)}>
                        <ArrowBackIcon/>
                    </IconButton >
                </Grid>
                <Grid item>
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <Typography variant="h5" component="h2">
                            <b>Add monitor: </b>{ship ? ship.name : ''}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Monitor details" />
                        <CardContent>
                            <TextField fullWidth label="Name" style={{margin: '10px 0px'}} variant="outlined" value={name} onChange={(e) => setName(e.target.value)}/>
                            <TextField select fullWidth style={{margin: '10px 0px'}} value={parser} variant="outlined" label="Parser" onChange={(e) => setParser(e.target.value)}>
                                {
                                    parsers &&
                                    Object.keys(parsers).map((p) => {
                                      return <MenuItem key={p} value={p}>{p}</MenuItem>
                                    })
                                }
                            </TextField>
                            {
                                parser in parsers &&
                                    parsers[parser].parameters.map((param) => {
                                        return <DataField parameter={param} data={data} key={param.name} keyName={param.name} setData={(key, value) => {
                                            setData({
                                                ...data,
                                                [key]: value
                                            });
                                        }}/>
                                    })

                            }
                            <Grid item align="right">
                                <Button variant="outlined" disabled={!IsValid()} onClick={() => AddMonitor()}>Create</Button>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}