/**
 * Created by Robin
 * Created on 04/03/2024
 */

import React, {useState, useEffect, useContext} from "react";

import UserContext from './../context/UserContext.js';
import APIContext from "../context/APIContext";

export default function User(props) {
    const API = useContext(APIContext);
    const [user, setUser] = useState({})

    useEffect(() => {
        API.getUserSelf().then((user) => {
            setUser(user);
        });
    }, [API]);

    return (
        <UserContext.Provider value={user}>
            {props.children}
        </UserContext.Provider>

    )
}