/**
 * Created by Robin on 18/07/2023.
 */

import React, {useContext, useEffect, useState, useRef} from "react";
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Chart from "react-apexcharts";

import APIContext from './../../../context/APIContext.js';


export default function SparkLine(props) {
    const API = useContext(APIContext);

    const startDateTimeRef = useRef(moment.utc().subtract(1, 'hours'));
    const endDateTimeRef = useRef(moment.utc());

    const [data, setData] = useState(false);
    const [maxHeight, setMaxHeight] = useState(props.minimumTopValue ? props.minimumTopValue : 0);

    function updateChartData() {
        startDateTimeRef.current = moment.utc().subtract(1, 'hours');
        endDateTimeRef.current = moment.utc();

        API.getDataRaw(props.ship.id, props.monitor, props.metric, startDateTimeRef.current.toDate(), endDateTimeRef.current.toDate()).then((data) => {

            let maxHeight = props.minimumTopValue ? props.minimumTopValue : 0;

            if (Array.isArray(data)) {
                let formattedData = [];
                for (let point of data) {
                    if (maxHeight < point.value) {
                        maxHeight = point.value;
                    }
                    formattedData.push([new Date(point.timestamp).getTime(), point.value])
                }
                setData(formattedData);
                setMaxHeight(maxHeight + Math.max((maxHeight * 0.05), 3));
            } else {
                setData([]);
            }
        })
    }

    useEffect(() => {
        updateChartData();
    }, []);

    useEffect(() => {
        if (props.updateInterval) {
            const interval = setInterval(() => {
                updateChartData();
            }, props.updateInterval * 1000);
            return () => clearInterval(interval);
        }
    }, [props.updateInterval]);

    const formatNumber = (number, digits) => {
        return new Intl.NumberFormat('nl-NL', {
            minimumFractionDigits: digits,
            maximumFractionDigits: digits
        }).format(number);
    };

    const getChartOptions = () => {
        return {
            chart: {
                type: 'area',
                sparkline: {
                    enabled: true
                },
                redrawOnParentResize: true,
                redrawOnWindowResize: true
            },
            xaxis: {
                type: 'datetime',
                min: startDateTimeRef.current.toDate().getTime(),
                max: endDateTimeRef.current.toDate().getTime(),
                labels: {
                    datetimeUTC: false
                }
            },
            yaxis: {
                min: 0,
                max: maxHeight,
                labels: {
                    show: false
                }
            },
            stroke: {
                curve: 'smooth',
                width: 2
            },
            fill: {
                type: 'solid',
                opacity: 0.3,
            },
            tooltip: {
                followCursor: true,
                fixed: {
                    enabled: false,
                    offsetX: 0
                },
                x: {
                    show: true,
                    format: 'dd MMM yyyy HH:mm:ss'
                },
                y: {
                    formatter: (data) => {
                        return formatNumber(data, 2) + ' ' + (props.unit ?? '');
                    },
                    title: {
                        formatter: function (seriesName) {
                            return ''
                        }
                    }
                },
                style: {
                    zIndex: 9999
                }
            }
        }
    };

    const getChartSeries = () => {
        return [
            {
                name: props.label ? props.label : "data",
                data: data
            }
        ]
    };

    if (data) {
        return (
            <div style={{
                width: props.width
            }}>
                <Chart
                    type='area'
                    options={getChartOptions()}
                    series={getChartSeries()}
                    height={props.height}
                />
            </div>
        )
    } else {
        return [];
    }
}