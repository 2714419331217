/**
 * Created by Robin on 07/01/2023.
 */

import React, {useState} from "react";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

function AddVolumeMapEntryDialog(props) {

    const [input, setInput] = useState(0.0);
    const [output, setOutput] = useState(0.0);
    const [inputText, setInputText] = useState('0.00');
    const [outputText, setOutputText] = useState('0.00');

    const handleClose = () => {
        if (typeof props.onClose === 'function') {
            props.onClose();
        }
    };

    const handleAdd = () => {
        if (typeof props.onAdd === 'function') {
            props.onAdd(input, output);
        }

        handleClose();
    };

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            aria-labelledby="add-entry-dialog-title"
            aria-describedby="add-entry-dialog-description"
        >
            <DialogTitle id="add entry-dialog-title">
                Add volume map entry
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={{md: 4, xs: 0}} sx={{padding: '10px'}}>
                    <Grid item xs={12} md={12}>
                        <TextField
                            fullWidth
                            label="Input"
                            type="text"
                            value={inputText}
                            variant="outlined"
                            onChange={(e) => {
                                setInputText(e.target.value)
                            }}
                            onBlur={(e) => {
                                let f = parseFloat(e.target.value);

                                if (isNaN(f) || !isFinite(f)) {
                                    f = 0;
                                }

                                setInput(f);
                                setInputText(f.toFixed(2))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField
                            fullWidth
                            label="Output"
                            type="text"
                            value={outputText}
                            variant="outlined"
                            onChange={(e) => {
                                setOutputText(e.target.value)
                            }}
                            onBlur={(e) => {
                                let f = parseFloat(e.target.value);

                                if (isNaN(f) || !isFinite(f)) {
                                    f = 0;
                                }

                                setOutput(f);
                                setOutputText(f.toFixed(2))
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAdd} color="success">Add</Button>
                <Button onClick={handleClose} autoFocus>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddVolumeMapEntryDialog;