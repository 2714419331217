/**
 * Created by Robin on 04/01/2023.
 */

import React from "react";

import Button from '@mui/material/Button';

import {Link} from "react-router-dom";

import TuneIcon from '@mui/icons-material/Tune';

function VolumeMapButton(props) {

    if (props.map === undefined || props.map === null || props.map.id === null) {
        return [];
    } else {
        return (
            <Button variant="text" style={{textTransform: 'none'}} startIcon={<TuneIcon />} component={Link} to={"/volume_map/" + props.map.id}>
                {props.map.displayName}
            </Button>
        );
    }
}

export default VolumeMapButton;