/**
 * Created by Robin on 06/01/2023.
 */

import React, {useContext, useEffect, useState, useCallback} from "react";

import APIContext from './../context/APIContext.js';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Chart from "react-apexcharts";

function SparkLine(props) {
    const API = useContext(APIContext);
    const [chartData, setChartData] = useState(false);

    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();

    const updateChartData = useCallback(() => {
        const endTimestamp = new Date();
        const startTimestamp = new Date(endTimestamp);
        startTimestamp.setHours(startTimestamp.getHours() - 1);

        const type = props.dataType ? props.dataType : 'Decimal';
        const shipId = props.shipId;
        const key = props.dataKey;


        API.getRawData(type, shipId, key, startTimestamp.toISOString(), endTimestamp.toISOString()).then((data) => {
            let formattedData = [];

            for (let point of data) {
                const value = point.value;
                formattedData.push([new Date(point.timestamp).getTime(), value])
            }

            setChartData(formattedData);
            setStartTime(startTimestamp);
            setEndTime(endTimestamp);
        });
    }, [API, props.dataKey, props.dataType, props.shipId]);

    useEffect(() => {
        updateChartData();

    }, [API, updateChartData]);

    useEffect(() => {
        if (props.updateInterval) {
            const interval = setInterval(() => {
                updateChartData();
            }, props.updateInterval * 1000);
            return () => clearInterval(interval);
        }
    }, [props.updateInterval, updateChartData]);

    const ValueDisplay = () => {
        const lastRow = chartData[chartData.length - 1];
        let lastValue = '?';

        if (lastRow) {
            lastValue = formatNumber(lastRow[1], 2);
        }

        if (props.unit) {
            return (
                <Grid container direction="column" width="70px" height="50px" style={{border: "1px solid rgba(0,0,0,0.10)"}} alignItems="center" justifyContent="space-evenly">
                    <Grid item>
                        <Typography variant="p" style={{fontSize: '16px'}}>
                            <b>{lastValue}</b>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="p" style={{fontSize: '12px'}}>
                            {props.unit}
                        </Typography>
                    </Grid>
                </Grid>
            )
        } else {
            return (
                <Grid container direction="column" width="70px" height="50px" style={{border: "1px solid rgba(0,0,0,0.10)"}} alignItems="center" justifyContent="space-evenly">
                    <Grid item>
                        <Typography variant="p" style={{fontSize: '16px'}}>
                            <b>{lastValue}</b>
                        </Typography>
                    </Grid>
                </Grid>
            )
        }
    };

    const formatNumber = (number, digits) => {
        return new Intl.NumberFormat('nl-NL', {
            minimumFractionDigits: digits,
            maximumFractionDigits: digits
        }).format(number);
    };

    const getChartOptions = () => {
        return {
            chart: {
                type: 'area',
                sparkline: {
                    enabled: true
                },
                redrawOnParentResize: true,
                redrawOnWindowResize: true
            },
            xaxis: {
                type: 'datetime',
                min: startTime.getTime(),
                max: endTime.getTime(),
                labels: {
                    datetimeUTC: false
                }
            },
            yaxis: {
                min: 0,
                max: props.maxHeight,
                labels: {
                    show: false
                }
            },
            stroke: {
                curve: 'smooth',
                width: 2
            },
            fill: {
                type: 'solid',
                opacity: 0.3,
            },
            tooltip: {
                followCursor: true,
                fixed: {
                    enabled: false,
                    offsetX: 0
                },
                x: {
                    show: true,
                    format: 'dd MMM yyyy HH:mm:ss'
                },
                y: {
                    formatter: (data) => {
                        return formatNumber(data, 2) + ' ' + (props.unit ?? '');
                    },
                    title: {
                        formatter: function (seriesName) {
                            return ''
                        }
                    }
                },
                style: {
                    zIndex: 9999
                }
            }
        }
    };

    const getChartSeries = () => {
        return [
            {
                name: props.label ?? "data",
                data: chartData
            }
        ]
    };

    if (chartData === false) {
        return (
            <div className="sparkline-chart">
                Loading...
            </div>
        )
    } else {
        return (
            <Grid container spacing={2} alignItems="center" wrap="nowrap">
                <Grid item flex="1">
                    <div className="sparkline-chart" style={{border: "1px solid rgba(0,0,0,0.10)"}}>
                        <Chart
                            type='area'
                            options={getChartOptions()}
                            series={getChartSeries()}
                            height="50px"
                        />
                    </div>
                </Grid>
                <Grid item>
                    <ValueDisplay />
                </Grid>
            </Grid>
        );
    }
}

export default SparkLine;