/**
 * Created by Robin on 27/07/2023.
 */

import React, {useContext, useEffect, useState} from "react";
import { useParams, useNavigate } from 'react-router-dom';


import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import CheckIcon from '@mui/icons-material/Check';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ReportIcon from '@mui/icons-material/Report';

export default function AlarmIcon(props) {
    if (props.type === "WARN") {
        return (
            <Box key="warn" sx={{
                display: 'inline-flex',
                color: 'darkorange',
                border: '1px solid darkorange',
                borderRadius: '5px',
                backgroundColor: '#f5ecd3',
                gap: '5px',
                alignItems: 'center',
                padding: '5px'
            }}>
                <WarningAmberIcon fontSize="small" color="darkorange"/>
            </Box>
        );
    } else if (props.type === "CRITICAL") {
        return (
            <Box key="critical" sx={{
                display: 'inline-flex',
                color: 'darkred',
                border: '1px solid darkred',
                borderRadius: '5px',
                backgroundColor: '#ffdee6',
                gap: '5px',
                alignItems: 'center',
                padding: '5px'
            }}>
                <ReportIcon fontSize="small" color="darkred"/>
            </Box>
        );
    } else {
        return [];
    }
}