/**
 * Created by Robin
 * Created on 04/03/2024
 */



import React, {useState} from "react";


const UserContext = React.createContext({});
UserContext.displayName = 'UserContext';

export default UserContext;