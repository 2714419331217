/**
 * Created by Robin on 04/01/2023.
 */

import React, {useContext, useEffect, useState} from "react";

import Skeleton from '@mui/material/Skeleton';

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import APIContext from './../context/APIContext.js';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import ListCardHeader from './../components/ListCardHeader.js';
import VolumeMapButton from './../components/VolumeMapButton.js';

import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

function VolumeMapList(props) {
    const API = useContext(APIContext);
    const [volumeMaps, setVolumeMaps] = useState(false);

    useEffect(() => {
        API.getVolumeMaps().then((volumeMaps) => {
            if (volumeMaps !== false) {
                setVolumeMaps(volumeMaps);
            }
        });
    }, [API]);

    const UserCard = () => {
        if (volumeMaps === false) {
            return (
                <Card>
                    <ListCardHeader title="Volume Map list" createLink="/volume_map/create"/>
                    <CardContent>
                        <Skeleton variant="rounded" height={20} width="50%" animation="pulse"/>
                        <br />
                        <Skeleton variant="rounded" height={20} width="25%" animation="pulse"/>
                        <br />
                        <Skeleton variant="rounded" height={20} width="75%" animation="pulse"/>
                        <br />
                        <Skeleton variant="rounded" height={20} width="50%" animation="pulse"/>
                    </CardContent>
                </Card>
            );
        } else {
            return (
                <Card>
                    <ListCardHeader title="Volume Map list" createLink="/volume_map/create"/>
                    <CardContent sx={{
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingBottom: '0px !important'
                    }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {volumeMaps.map((map) => (
                                    <TableRow
                                        key={map.id}

                                    >
                                        <TableCell><VolumeMapButton map={map}/></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            );
        }
    }
    return <Grid container spacing={24}>
        <Grid item xs={12} sm={12}>
            <UserCard />
        </Grid>
    </Grid>
}

export default VolumeMapList;