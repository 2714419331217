/**
 * Created by Robin on 04/01/2023.
 */

import React, {useContext, useEffect, useState} from "react";
import { useParams, useNavigate, Link } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';

import APIContext from '../../context/APIContext.js';

import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import InvalidResource from '../../components/InvalidResource.js';

import UserSelector from '../../components/UserSelector.js';
import MonitorRow from './MonitorRow.js';
import ChartRow from "./ChartRow";


function ShipEdit(props) {
    const API = useContext(APIContext);
    const [ship, setShip] = useState(null);
    const [monitors, setMonitors] = useState(null);
    const [charts, setCharts] = useState(null);
    const [updatePending, setUpdatePending] = useState(false);

    const [name, setName] = useState('');
    const [mmsi, setMmsi] = useState('');
    const [safetyUser, setSafetyUser] = useState(null);
    const [technicalUser, setTechnicalUser] = useState(null);

    const navigate = useNavigate();
    let {id} = useParams();

    useEffect(() => {
        API.getShip(id).then((shipData) => {
            setShip(shipData);
        });

        API.getShipMonitors(id).then((monitors) => {
            setMonitors(monitors);
        });

        API.getShipCharts(id).then((charts) => {
            setCharts(charts);
        });
    }, [id, API]);

    useEffect(() => {
        if (ship) {
            setName(ship.name);
            setMmsi(ship.mmsi);
            setSafetyUser(ship.safetyUser.id);
            setTechnicalUser(ship.technicalUser.id);
        }
    }, [ship]);

    const updateShip = () => {
        const data = {
            name: name,
            mmsi: mmsi,
            safetyUserId: safetyUser,
            technicalUserId: technicalUser
        };

        setUpdatePending(true);

        API.setShip(id, data).then((res) => {
            if (res) {
                API.getShip(id).then((shipData) => {
                    setShip(shipData);
                    setUpdatePending(false);
                    navigate('/ship/' + shipData.id, {replace: true});
                });
            } else {
                alert('Failed to update ship');
                setUpdatePending(false);
            }
        })
    };

    const deleteMonitor = async (monitorId) => {
        await API.deleteShipMonitor(id, monitorId);

        API.getShipMonitors(id).then((monitors) => {
            setMonitors(monitors);
        });
    };

    const deleteChart = async (chartId) => {
        await API.deleteShipChart(id, chartId);

        API.getShipCharts(id).then((charts) => {
            setCharts(charts);
        });
    };

    const moveChart = (chartId, up) => {
        if (up) {
            API.moveShipChartUp(id, chartId).then(() => {
                API.getShipCharts(id).then((charts) => {
                    setCharts(charts);
                });
            });
        } else {
            API.moveShipChartDown(id, chartId).then(() => {
                API.getShipCharts(id).then((charts) => {
                    setCharts(charts);
                });
            });
        }
    };

    if (ship === false) {
        return (
            <InvalidResource type="ship" id={id}/>
        )
    } else if (ship === null) {
        return <p>Loading...</p>;
    } else {
        return (
            <React.Fragment>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{marginBottom: '20px'}}
                    spacing="10px"
                >
                    <Grid item>
                        <IconButton size="small" variant="text" onClick={() => navigate(-1)}>
                            <ArrowBackIcon/>
                        </IconButton >
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <Typography variant="h5" component="h2">
                                <b>Ship:</b> {ship.name}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <Card>
                            <CardHeader title={
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography variant="h6">
                                        Device details
                                    </Typography>
                                    <LoadingButton loading={updatePending} variant="contained" startIcon={<SaveIcon />}
                                                   onClick={updateShip}>
                                        Save
                                    </LoadingButton>
                                </Grid>
                            }/>
                            <CardContent>
                                <Grid container spacing={{md: 8, xs: 0}}>
                                    <Grid item xs={12} md={6}>
                                        <TextField fullWidth label="Name" style={{margin: '10px 0px'}} variant="outlined" value={name} onChange={(e) => setName(e.target.value)}/>
                                        <TextField fullWidth label="MMSI" style={{margin: '10px 0px'}} variant="outlined" value={mmsi === null ? '' : mmsi} onChange={(e) => setMmsi(e.target.value)}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <UserSelector allowNull={true} value={safetyUser} label="Safety User" onChange={(e) => setSafetyUser(e.target.value)}/>
                                        <UserSelector allowNull={true} value={technicalUser} label="Technical User" onChange={(e) => setTechnicalUser(e.target.value)}/>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>


                    <Grid item md={12} xs={12}>
                        <Card>
                            <CardHeader
                                title={
                                    <Typography variant="h6">
                                        Monitors
                                    </Typography>
                                }
                                action={
                                    <Button variant="outlined" startIcon={<AddIcon />} component={Link} to={'/ship/' + ship.id + '/monitor/add'}>Add</Button>
                                }
                            />
                            {
                                !monitors && <p>Loading...</p>
                            }
                            {
                                monitors && monitors.length === 0 &&
                                <p>No monitors defined</p>
                            }
                            {
                                monitors && monitors.length > 0 &&
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Parser</TableCell>
                                                <TableCell align="right">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                monitors.map((monitor) => {
                                                    return (
                                                        <MonitorRow monitor={monitor} ship={ship} key={monitor.id} deleteMonitor={deleteMonitor}/>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                        </Card>
                    </Grid>

                    <Grid item md={12} xs={12}>
                        <Card>
                            <CardHeader
                                title={
                                    <Typography variant="h6">
                                        Insight Charts
                                    </Typography>
                                }
                                action={
                                    <Button variant="outlined" startIcon={<AddIcon />} component={Link} to={'/ship/' + ship.id + '/chart/add'}>Add</Button>
                                }
                            />
                            {!charts && <p>Loading...</p>}
                            {charts &&
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Name
                                            </TableCell>
                                            <TableCell>
                                                Monitor
                                            </TableCell>
                                            <TableCell>
                                                Type
                                            </TableCell>
                                            <TableCell align="right">
                                                Actions
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            charts.map((chart) => {
                                                return (
                                                    <ChartRow
                                                        key={chart.id}
                                                        ship={ship}
                                                        chart={chart}
                                                        deleteChart={deleteChart}
                                                        moveChartUp={(chartId) => { moveChart(chartId, true)}}
                                                        moveChartDown={(chartId) => { moveChart(chartId, false)}}
                                                    />
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            }
                        </Card>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default ShipEdit;