/**
 * Created by Robin on 17/12/2022.
 */

import { createTheme } from '@mui/material/styles';
import { green, orange } from '@mui/material/colors';


// https://bareynol.github.io/mui-theme-creator/
const borderColor = '#e8e8e8';


export default createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#133262',
        },
        secondary: {
            main: '#e29233',
        },
        border: borderColor
    },
    shape: {
        borderRadius: 1,
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
        MuiListItemButton: {
            disableRipple: true
        }
    },
    typography: {
        h1: {
            fontSize: '2rem',
        },
        h2: {
            fontSize: '1.8rem',
        },
        h3: {
            fontSize: '1.6rem',
        },
        h4: {
            fontSize: '1.4rem',
        },
        h5: {
            fontSize: '1.2rem',
        },
        h6: {
            fontSize: '1rem',
        },
    },
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: ( {ownerState} ) => ownerState.className === "MainMenu" ? {
                    backgroundColor: '#fafaff'
                } : {},
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fafaff',
                    borderBottom: '1px solid ' + borderColor,
                    padding: '8px 16px'
                },
                title: {
                    fontSize: '1rem'
                }
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    '& .MuiTableCell-root': {
                        backgroundColor: '#fafaff'
                    }
                }
            }
        },
        MuiTable: {
            styleOverrides: {
                root: ({ownerState}) => ({
                    ...(ownerState.variant === "popout" && {
                        /*
                         backgroundColor: '#515154',
                         color: '#dedede',
                         '& .MuiTableCell-root': {
                         color: '#dedede',
                         border: 0
                         },
                         */
                    })
                })
            }
        }
    }
});

/*
import { createTheme } from '@mui/material/styles';

export default createTheme({
    shape: {
        borderRadius: 0
    },
    palette: {
        background: {
            default: "#F1F3F4"
        },
        mode: 'light',
    },
    custom: {
        palette: {
            iconColor: '#5f6368',
        }
    }
});
*/