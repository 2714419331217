/**
 * Created by Robin on 19/12/2022.
 */

import Tooltip from '@mui/material/Tooltip';

function TimeAgoTooltipWrapper(props) {
    let { verboseDate, children, ...rest } = props;
    return (
        <Tooltip {...rest} title={verboseDate}>
            {children}
        </Tooltip>
    );
}

export default TimeAgoTooltipWrapper;