/**
 * Created by Robin on 17/12/2022.
 */

import { createTheme } from '@mui/material/styles';

export default createTheme({
    shape: {
        borderRadius: 0
    },
    palette: {
        mode: 'dark',
    }
});