/**
 * Created by Robin on 04/01/2023.
 */

import React, {useContext, useEffect, useState, useCallback} from "react";
import { useParams, useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import APIContext from './../context/APIContext.js';
import DeleteIcon from '@mui/icons-material/Delete';

import InvalidResource from './../components/InvalidResource.js';
import ListCardHeader from './../components/ListCardHeader.js';
import ShipButton from './../components/ShipButton.js';
import UserButton from './../components/UserButton.js';

import ShipSelector from './../components/ShipSelector.js';
import UserSelector from './../components/UserSelector.js';


function GroupEdit(props) {
    const API = useContext(APIContext);
    const [group, setGroup] = useState(null);

    const [showAddShip, setShowAddShip] = useState(false);
    const [showAddUser, setShowAddUser] = useState(false);

    const [addShip, setAddShip] = useState(null);
    const [addUser, setAddUser] = useState(null);

    const navigate = useNavigate();
    let {id} = useParams();

    const reloadGroup = useCallback(() => {
        API.getGroup(id).then((groupData) => {
            setGroup(groupData);
        });
    },[API, id]);

    useEffect(() => {
        reloadGroup();
    }, [reloadGroup]);

    const handleCloseAddShip = () => {
        setShowAddShip(false);
        setAddShip(null);
    };

    const handleAddShip = () => {
        API.addShipToGroup(id, addShip).then((res) => {
            if (!res) {
                alert('Failed to add ship to group');
            }
            handleCloseAddShip();
            reloadGroup();
        });
    };

    const handleCloseAddUser = () => {
        setShowAddUser(false);
        setAddUser(null);
    };

    const handleAddUser = () => {
        API.addUserToGroup(id, addUser).then((res) => {
            if (!res) {
                alert('Failed to add user to group');
            }
            handleCloseAddUser();
            reloadGroup();
        });
    };

    const handleRemoveUser = (userId) => {
        API.removeUserFromGroup(id, userId).then((res) => {
            if (!res) {
                alert('Failed to remove user from group');
            }
            reloadGroup();
        });
    };

    const handleRemoveShip = (shipId) => {
        API.removeShipFromGroup(id, shipId).then((res) => {
            if (!res) {
                alert('Failed to remove ship from group');
            }
            reloadGroup();
        });
    };

    const handleRemoveGroup = () => {
        API.deleteGroup(id).then((res) => {
            if (res) {
                navigate(-1);
            } else {
                alert('Failed to remove group');
            }
        })
    };

    /*
    const updateMetric = () => {
        const data = {
            displayName: displayName,
            type: type,
            parser: parser
        };

        setUpdatePending(true);

        API.setMetric(id, data).then((res) => {
            if (res) {
                navigate(-1);
            } else {
                alert('Failed to update metric');
            }
            setUpdatePending(false);
        })
    };
    */

    if (group === false) {
        return (
            <InvalidResource type="group" id={id}/>
        )
    } else if (group === null) {
        return <p>Loading...</p>;
    } else {
        return (
            <React.Fragment>
                <Dialog open={showAddShip} onClose={handleCloseAddShip}>
                    <DialogTitle>
                        Add ship to {group.name}
                    </DialogTitle>
                    <DialogContent>
                        Please choose a ship:
                        <ShipSelector fullWidth label="Ship" style={{margin: '20px 0px'}} variant="outlined" value={addShip} onChange={(e) => setAddShip(e.target.value)}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseAddShip}>Cancel</Button>
                        <Button onClick={handleAddShip}>Add</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={showAddUser} onClose={handleCloseAddUser}>
                    <DialogTitle>
                        Add user to {group.name}
                    </DialogTitle>
                    <DialogContent>
                        Please choose a user:
                        <UserSelector fullWidth label="User" style={{margin: '20px 0px'}} variant="outlined" value={addUser} onChange={(e) => setAddUser(e.target.value)}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseAddUser}>Cancel</Button>
                        <Button onClick={handleAddUser}>Add</Button>
                    </DialogActions>
                </Dialog>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            style={{marginBottom: '20px'}}
                            spacing="10px"
                        >
                            <Grid item>
                                <IconButton size="small" variant="text" onClick={() => navigate(-1)}>
                                    <ArrowBackIcon/>
                                </IconButton >
                            </Grid>
                            <Grid item>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Typography variant="h5" component="h2">
                                        <b>Group:</b> {group.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {
                            group.group_ship_members.length === 0 && group.group_user_members.length === 0 ?
                                <Button color="error" variant="outlined" startIcon={<DeleteIcon />} onClick={() => {handleRemoveGroup()}}>Remove Group</Button>
                            :
                                ""
                        }
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <Card>
                            <ListCardHeader title="Ships" createCallback={() => {setShowAddShip(true)}} createLabel="Add ship"/>
                            <CardContent sx={{
                                paddingTop: 0,
                                paddingLeft: 0,
                                paddingRight: 0,
                                paddingBottom: '0px !important'
                            }}>
                                {group.group_ship_members.length > 0 ?
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell align="right">Delete</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {group.group_ship_members.map((ship_member) => (
                                                <TableRow
                                                    key={ship_member.id}

                                                >
                                                    <TableCell><ShipButton ship={ship_member.ship}/></TableCell>
                                                    <TableCell align="right"><Button color="error" startIcon={<DeleteIcon />} onClick={() => handleRemoveShip(ship_member.ship.id)}>Remove</Button></TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    :
                                    <CardContent><b>No ships in group</b></CardContent>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Card>
                            <ListCardHeader title="Users" createCallback={() => {setShowAddUser(true)}} createLabel="Add user"/>
                            <CardContent sx={{
                                paddingTop: 0,
                                paddingLeft: 0,
                                paddingRight: 0,
                                paddingBottom: '0px !important'
                            }}>
                                {group.group_user_members.length > 0 ?
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell align="right">Delete</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {group.group_user_members.map((user_member) => (
                                                <TableRow
                                                    key={user_member.id}

                                                >
                                                    <TableCell><UserButton user={user_member.user} /></TableCell>
                                                    <TableCell align="right"><Button color="error" startIcon={<DeleteIcon />} onClick={() => handleRemoveUser(user_member.user.id)}>Remove</Button></TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                :
                                    <CardContent><b>No users in group</b></CardContent>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default GroupEdit;