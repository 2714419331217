/**
 * Created by Robin on 04/01/2023.
 */

import React from "react";

import Button from '@mui/material/Button';

import {Link} from "react-router-dom";

import TroubleshootIcon from '@mui/icons-material/Troubleshoot';


function InsightButton(props) {

    if (props.ship === undefined || props.shop === null || props.ship.id === null) {
        return [];
    } else {
        return (
            <Button variant="text" size="small" style={{textTransform: 'none'}} startIcon={<TroubleshootIcon />} component={Link} to={"/insight/" + props.ship.id}>
                {props.ship.name}
            </Button>
        );
    }
}

export default InsightButton;