/**
 * Created by Robin on 19/12/2022.
 */

import React, {useContext, useEffect, useState} from "react";

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';
import ListSubheader from '@mui/material/ListSubheader';

import APIContext from './../context/APIContext.js';

function GroupSelector(props) {
    const API = useContext(APIContext);
    const [groups, setGroups] = useState(false);

    useEffect(() => {
        API.getGroupCategories().then((groupData) => {
            setGroups(groupData);
        });
    }, [API]);

    if (groups === false) {
        return <Skeleton variant="rounded" height={20} width="100%" animation="pulse"/>
    } else {
        let items = [];

        if (props.allowNull === true) {
            items.push(<MenuItem key={null} value={null}><i>(None)</i></MenuItem>);
        }

        for (const cat of groups) {
            if (cat.groups.length > 0) {
                let groupItems = [];

                items.push(<ListSubheader key={cat.id}>{cat.name}</ListSubheader>);
                for (const group of cat.groups) {
                    items.push(<MenuItem key={cat.id + '-' + group.id} value={group.id}>{group.name}</MenuItem>);
                }
            }
        }

        return (
            <TextField fullWidth select style={{margin: '10px 0px'}} value={props.value || ''} variant="outlined" label={props.label ?? "Group"} onChange={props.onChange}>
                {items}
            </TextField>
        )
    }
}

export default GroupSelector;