/**
 * Created by Robin on 04/01/2023.
 */

import React, {useContext, useEffect, useState, useCallback} from "react";

import Skeleton from '@mui/material/Skeleton';

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";

import APIContext from './../context/APIContext.js';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import DeleteIcon from '@mui/icons-material/Delete';

import DeviceButton from './../components/DeviceButton.js';
import ShipButton from './../components/ShipButton.js';
import ListCardHeader from './../components/ListCardHeader.js';
import ConfirmDeleteDialog from './../components/ConfirmDeleteDialog.js';

function DeviceList(props) {
    const API = useContext(APIContext);
    const [devices, setDevices] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [deviceToDelete, setDeviceToDelete] = useState(null);

    const reloadDevices = useCallback(() => {
        API.getDevices().then((devices) => {
            if (devices !== false) {
                setDevices(devices);
            }
        });
    }, [API]);

    useEffect(() => {
        reloadDevices();
    }, [API, reloadDevices]);

    const deleteDevice = async () => {
        if (await API.deleteDevice(deviceToDelete.id)) {
            reloadDevices();
        } else {
            alert('Failed to delete device');
        }
    };

    const DeviceCard = () => {
        if (devices === false) {
            return (
                <Card>
                    <ListCardHeader title="Device list"/>
                    <CardContent>
                        <Skeleton variant="rounded" height={20} width="50%" animation="pulse"/>
                        <br />
                        <Skeleton variant="rounded" height={20} width="25%" animation="pulse"/>
                        <br />
                        <Skeleton variant="rounded" height={20} width="75%" animation="pulse"/>
                        <br />
                        <Skeleton variant="rounded" height={20} width="50%" animation="pulse"/>
                    </CardContent>
                </Card>
            );
        } else {
            return (
                <Card>
                    <ListCardHeader title="Device list" createLink="/device/create"/>
                    <CardContent sx={{
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingBottom: '0px !important'
                    }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Assigned Ship</TableCell>
                                    <TableCell align="right">Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {devices.map((device) => (
                                    <TableRow
                                        key={device.id}

                                    >
                                        <TableCell><DeviceButton device={device}/></TableCell>
                                        <TableCell><ShipButton ship={device.ship}/></TableCell>
                                        <TableCell align="right">
                                            <Button variant="outlined" startIcon={<DeleteIcon />}
                                                    onClick={() => {
                                                        setDeviceToDelete(device);
                                                        setShowDeleteDialog(true);
                                                    }} color="error">
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </CardContent>
                    <ConfirmDeleteDialog open={showDeleteDialog} onClose={() => { setShowDeleteDialog(false) }} onDelete={deleteDevice}>
                        <p>
                            You are about to delete the device <b>{deviceToDelete?.name}</b><br />
                            No new data from this device will be parsed and collected!
                        </p>
                        <p>
                            Are you sure?
                        </p>
                    </ConfirmDeleteDialog>
                </Card>
            );
        }
    }
    return <Grid container spacing={24}>
        <Grid item xs={12} sm={12}>
            <DeviceCard />
        </Grid>
    </Grid>
}

export default DeviceList;