/**
 * Created by Robin on 04/01/2023.
 */

import React, {useContext, useEffect, useState} from "react";
import { useParams, useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';

import APIContext from './../context/APIContext.js';

import InvalidResource from './../components/InvalidResource.js';
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDeleteDialog from "../components/ConfirmDeleteDialog";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddVolumeMapEntryDialog from "../components/AddVolumeMapEntryDialog";


function VolumeMapEdit(props) {
    const API = useContext(APIContext);

    const [volumeMap, setVolumeMap] = useState(null);
    const [entries, setEntries] = useState(null);
    const [updatePending, setUpdatePending] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showAddEntryDialog, setShowAddEntryDialog] = useState(false);

    const [displayName, setDisplayName] = useState('');

    const navigate = useNavigate();
    let {id} = useParams();

    useEffect(() => {
        API.getVolumeMap(id).then((map) => {
            setVolumeMap(map);
        });

    }, [id, API]);

    useEffect(() => {
        if (volumeMap) {
            setDisplayName(volumeMap.displayName);
            reloadEntries();
        }
    }, [API, volumeMap]);

    const reloadEntries = () => {
        API.getVolumeMapEntries(volumeMap.id).then((entries) => {
            setEntries(entries);
        });
    }

    const deleteVolumeMap = async () => {
        if (await API.deleteVolumeMap(volumeMap.id)) {
            navigate(-1);
        } else {
            alert('Failed to delete volume map');
        }
    };

    const addVolumeMapEntry = (input, output) => {
        API.createVolumeMapEntry(volumeMap.id, {
            input, output
        }).then(() => {
            reloadEntries();
        });
    }

    const updateVolumeMap = () => {
        const data = {
            displayName: displayName,
        };

        setUpdatePending(true);

        API.setVolumeMap(id, data).then((res) => {
            if (res) {
                API.getVolumeMap(id).then((map) => {
                    setVolumeMap(map);
                    setUpdatePending(false);
                    navigate('/volume_map/' + map.id, {replace: true});
                });
            } else {
                alert('Failed to update the volume map');
                setUpdatePending(false);
            }
        })
    };

    const handleRemoveEntry = (entryId) => {
        API.deleteVolumeMapEntry(volumeMap.id, entryId).then((res) => {
            if (!res) {
                alert('Failed to remove volume map entry');
            }
            reloadEntries();
        });
    };

    if (volumeMap === false) {
        return (
            <InvalidResource type="map" id={id}/>
        )
    } else if (volumeMap === null) {
        return <p>Loading...</p>;
    } else {
        return (
            <React.Fragment>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{marginBottom: '20px'}}
                    spacing="10px"
                >
                    <Grid item>
                        <IconButton size="small" variant="text" onClick={() => navigate(-1)}>
                            <ArrowBackIcon/>
                        </IconButton >
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <Typography variant="h5" component="h2">
                                <b>Volume Map:</b> {volumeMap.displayName}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title={
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography variant="h6">
                                        Volume Map details
                                    </Typography>
                                    <Grid item>
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <LoadingButton loading={updatePending} variant="contained" startIcon={<SaveIcon />}
                                                               onClick={updateVolumeMap}>
                                                    Save
                                                </LoadingButton>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="contained" startIcon={<DeleteIcon />}
                                                        onClick={() => setShowDeleteDialog(true)} color="error">
                                                    Delete
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }/>
                            <CardContent>
                                <Grid container spacing={{md: 4, xs: 0}}>
                                    <Grid item xs={12} md={12}>
                                        <TextField fullWidth label="Display name" style={{margin: '10px 0px'}} variant="outlined" value={displayName} onChange={(e) => setDisplayName(e.target.value)}/>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title={
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography variant="h6">
                                        Volume mappings
                                    </Typography>
                                    <Grid item>
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <Button variant="contained" startIcon={<AddIcon />}
                                                        onClick={() => setShowAddEntryDialog(true)}>
                                                    Add
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }/>
                                {entries === null &&
                                    <CardContent>
                                        Loading entries...
                                    </CardContent>
                                }
                                {entries &&
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Input</TableCell>
                                                <TableCell>Output</TableCell>
                                                <TableCell />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {entries.map((entry) => {
                                                return (
                                                    <TableRow key={entry.id}>
                                                        <TableCell>{entry.input}</TableCell>
                                                        <TableCell>{entry.output}</TableCell>
                                                        <TableCell align="right"><Button color="error" startIcon={<DeleteIcon />} onClick={() => {handleRemoveEntry(entry.id)}}>Remove</Button></TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                }
                        </Card>
                    </Grid>
                </Grid>

                <ConfirmDeleteDialog open={showDeleteDialog} onClose={() => { setShowDeleteDialog(false) }} onDelete={deleteVolumeMap}>
                    <p>
                        You are about to delete the volume map <b>{volumeMap.displayName}</b><br />
                    </p>
                    <p>
                        Are you sure?
                    </p>
                </ConfirmDeleteDialog>
                <AddVolumeMapEntryDialog open={showAddEntryDialog} onAdd={addVolumeMapEntry} onClose={() => setShowAddEntryDialog(false)}/>
            </React.Fragment>
        );
    }
}

export default VolumeMapEdit;