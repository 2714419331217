/**
 * Created by Robin on 04/01/2023.
 */

import React from "react";

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import {Link} from "react-router-dom";

import GroupWorkIcon from '@mui/icons-material/GroupWork';

function GroupButton(props) {

    if (props.group === undefined || props.group === null || props.group.id === null) {
        return [];
    } else {
        return (
            <Button variant="text" style={{textTransform: 'none'}} startIcon={<GroupWorkIcon />} component={Link} to={"/group/" + props.group.id}>
                {props.group.name}
            </Button>
        );
    }
}

export default GroupButton;