/**
 * Created by Robin on 04/01/2023.
 */

import React, {useContext, useEffect, useState} from "react";
import { useParams, useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';

import APIContext from './../context/APIContext.js';

import InvalidResource from './../components/InvalidResource.js';
import PasswordField from "../components/PasswordField";


function UserEdit(props) {
    const API = useContext(APIContext);
    const [user, setUser] = useState(null);
    const [updatePending, setUpdatePending] = useState(false);

    const [displayName, setDisplayName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [active, setActive] = useState(false);
    const [admin, setAdmin] = useState(false);
    const [notifications, setNotifications] = useState(false);

    const navigate = useNavigate();
    let {id} = useParams();

    useEffect(() => {
        API.getUser(id).then((userData) => {
            setUser(userData);
        });

    }, [id, API]);

    useEffect(() => {
        if (user) {
            setDisplayName(user.displayName);
            setEmail(user.email);
            setPhone(user.phone);
            setActive(user.active);
            setAdmin(user.admin);
            setNotifications(user.allowAlarmNotifications);
        }
    }, [user]);

    const updateUser = () => {
        const data = {
            displayName: displayName,
            email: email,
            active: active,
            phone: phone,
            admin: admin,
            allowAlarmNotifications: notifications
        };

        if (password.length > 0) {
            data.password = password;
        }

        setUpdatePending(true);

        API.setUser(id, data).then((res) => {
            if (res) {
                API.getUser(id).then((userData) => {
                    setUser(userData);
                    setUpdatePending(false);
                    navigate('/user/' + userData.id, {replace: true});
                });
            } else {
                alert('Failed to update the user');
                setUpdatePending(false);
            }
        })
    };

    if (user === false) {
        return (
            <InvalidResource type="user" id={id}/>
        )
    } else if (user === null) {
        return <p>Loading...</p>;
    } else {
        return (
            <React.Fragment>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{marginBottom: '20px'}}
                    spacing="10px"
                >
                    <Grid item>
                        <IconButton size="small" variant="text" onClick={() => navigate(-1)}>
                            <ArrowBackIcon/>
                        </IconButton >
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <Typography variant="h5" component="h2">
                                <b>User:</b> {user.displayName}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Card>
                    <CardHeader title={
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography variant="h6">
                                User details
                            </Typography>
                            <LoadingButton loading={updatePending} variant="contained" startIcon={<SaveIcon />}
                                           onClick={updateUser}>
                                Save
                            </LoadingButton>
                        </Grid>
                    }/>
                    <CardContent>
                        <Grid container spacing={{md: 4, xs: 0}}>
                            <Grid item xs={12} md={6}>
                                <TextField fullWidth label="Display name" style={{margin: '10px 0px'}} variant="outlined" value={displayName} onChange={(e) => setDisplayName(e.target.value)}/>
                                <TextField fullWidth label="E-mail" style={{margin: '10px 0px'}} variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)}/>
                                <TextField fullWidth label="Phone" style={{margin: '10px 0px'}} variant="outlined" value={phone} onChange={(e) => setPhone(e.target.value)}/>
                                <PasswordField fullWidth label="New Password" style={{margin: '10px 0px'}} variant="outlined" value={password} onChange={(e) => setPassword(e.target.value)}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormGroup style={{margin: "19px 0px"}}>
                                    <FormControlLabel control={<Switch checked={!!notifications} onChange={(e) => {setNotifications(!notifications)}} />} label="Alarm notifications" />
                                </FormGroup>
                                <FormGroup style={{margin: "19px 0px"}}>
                                    <FormControlLabel control={<Switch checked={!!active} onChange={(e) => {setActive(!active)}} />} label="Active" />
                                </FormGroup>
                                <FormGroup style={{margin: "19px 0px"}}>
                                    <FormControlLabel control={<Switch checked={!!admin} onChange={(e) => {setAdmin(!admin)}} />} label="Admin" />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </React.Fragment>
        );
    }
}

export default UserEdit;