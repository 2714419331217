/**
 * Created by Robin on 18/12/2022.
 */

import React, {useContext} from "react";
//import Avatar from 'react-avatar';

import { styled, alpha } from '@mui/system';

import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';

import APIContext from './../context/APIContext.js';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PasswordIcon from '@mui/icons-material/Password';
import LogoutIcon from '@mui/icons-material/Logout';
import {Link, useNavigate} from "react-router-dom";


const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(57, 62, 92)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 22,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    if (name.split(' ').length > 1) {
        return {
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    } else {
        return {
            children: `${name[0]}`,
        }
    }
}

function ProfileAvatar(props) {
    const API = useContext(APIContext);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const navigate = useNavigate();

    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Avatar {...stringAvatar(API.user.displayName)} sx={{bgcolor: '#fff', color: (theme) => theme.palette.primary.light,}} onClick={openMenu}>

            </Avatar>
            <StyledMenu
                anchorEl={anchorEl}
                open={anchorEl !== null}
                onClick={closeMenu}
                onClose={closeMenu}
            >
                <ListItem>
                    <div style={{display: 'block'}}>
                        <Typography variant="subtitle1">{API.user.displayName}</Typography>
                        <Typography variant="subtitle2">{API.user.email}</Typography>
                    </div>
                </ListItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem component={Link} to="/password">
                    <PasswordIcon />
                    <Typography variant="caption">Change password</Typography>
                </MenuItem>
                <MenuItem onClick={API.logoff}>
                    <LogoutIcon />
                    <Typography variant="caption">Sign out</Typography>
                </MenuItem>
            </StyledMenu>
        </React.Fragment>
    )
}

export default ProfileAvatar;