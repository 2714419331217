/**
 * Created by Robin on 19/12/2022.
 */

import React, {useContext} from "react";

import { Viewer } from '@toast-ui/react-editor';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';

import ReactTimeAgo from 'react-time-ago';
import TimeAgoTooltipWrapper from './TimeAgoTooltipWrapper.js';

import APIContext from './../context/APIContext.js';

TimeAgo.addDefaultLocale(en);

function TicketPost(props) {
    const API = useContext(APIContext);

    const downloadFile = async (hash, fileName) => {
        const data = await API.downloadFile(hash);
        if (data === false) {
            alert('Download failed');
        } else {
            const blob = new Blob([data]);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        }
    };

    const renderFiles = () => {
        let ret = [];
        props.post.data.ticket_post_attachments.forEach((file) => {
            ret.push(
                <Button key={file.filename} size="small" startIcon={<AttachFileIcon />} style={{textTransform: 'none'}} onClick={(e) => {
                    downloadFile(file.fileHash, file.filename);
                }}>
                    {file.filename}
                </Button>
            );
        });
        
        return ret;
    };

    const PostInfo = () => {
        return (
            <React.Fragment>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Button variant="text" style={{textTransform: 'none'}} startIcon={<AccountCircleIcon />}>
                        {props.post.user.displayName}
                    </Button>
                    <Typography color="textSecondary">
                        <ReactTimeAgo date={new Date(props.post.createdAt)} locale="en-US" timeStyle="round" wrapperComponent={TimeAgoTooltipWrapper}/>
                    </Typography>
                </Grid>
            </React.Fragment>
        );
    };

    return <Card style={{width: '100%', marginTop: '10px', marginBottom: '10px'}}>
        <CardHeader title={<PostInfo />}>

        </CardHeader>
        <Divider />
        <CardContent>
                <Viewer initialValue={props.post.data.content} />
                {renderFiles()}
        </CardContent>
    </Card>
}

export default TicketPost;