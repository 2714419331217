/**
 * Created by Robin on 26/07/2023.
 */

import React, {useContext, useEffect, useState} from "react";
import { useParams, useNavigate, Link } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';

import ConfirmDeleteDialog from './../../components/ConfirmDeleteDialog.js';

export default function MonitorRow(props) {
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    return (
        <TableRow key={props.monitor.id}>
            <TableCell>
                {props.monitor.displayName}
            </TableCell>
            <TableCell>
                {props.monitor.parser}
            </TableCell>
            <TableCell align="right">
                <IconButton component={Link} to={'/ship/' + props.ship.id + '/monitor/' + props.monitor.id}>
                    <CreateIcon />
                </IconButton>
                <IconButton variant="contained" color="error" onClick={() => setShowDeleteDialog(true)}>
                    <DeleteIcon />
                </IconButton>
            </TableCell>
            <ConfirmDeleteDialog open={showDeleteDialog} onClose={() => { setShowDeleteDialog(false) }} onDelete={() => props.deleteMonitor(props.monitor.id)}>
                <p>
                    You are about to delete the monitor <b>{props.monitor.displayName}</b><br />
                    You will lose all metrics collected by this monitor!
                </p>
                <p>
                    Are you sure?
                </p>
            </ConfirmDeleteDialog>
        </TableRow>
    )
}