/**
 * Created by Robin on 24/07/2023.
 */

import React, {useContext, useEffect, useState} from "react";
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';

import APIContext from './../../context/APIContext.js';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import LineChart from './Charts/LineChart.js';
import MapChart from './Charts/MapChart';

export default function ChartTile(props) {
    const API = useContext(APIContext);
    const [data, setData] = useState(null);

    const [startDate, setStartDate] = useState(moment.utc().subtract(1, 'hours'));
    const [endDate, setEndDate] = useState(moment.utc().subtract(1, 'hours'));
    const [max, setMax] = useState(0);

    useEffect(() => {
        if (props.interval === '1h') {
            updateChartData1H();
        } else if (props.interval === '1d') {
            updateChartData1D();
        } else if (props.interval === '30d') {
            updateChartData30D();
        }
    }, [props.date, props.interval, props.shipId, props.chart]);

    function updateChartData1H() {
        const startDate = moment.utc(props.date);
        const endDate = moment.utc(props.date).add(1, 'hours');

        API.getDataRaw(props.shipId, props.chart.metric.monitor.displayName, props.chart.metric.name, startDate.toDate(), endDate.toDate()).then((data) => {
            let max = props.chart.expectedMax;

            if (Array.isArray(data)) {
                let formattedData = [];

                for (let point of data) {
                    if (props.chart.metric.dataType === 'Integer' || props.chart.metric.dataType === 'Decimal') {
                        if (point.value > max) {
                            max = point.value;
                        }
                    }
                    formattedData.push([new Date(point.timestamp).getTime(), point.value])
                }

                setData(formattedData);
            } else {
                setData([]);
            }

            setStartDate(startDate);
            setEndDate(endDate);
            if (props.chart.metric.dataType === 'Integer' || props.chart.metric.dataType === 'Decimal') {
                setMax(max);
            }
        })
    }

    function updateChartData1D() {
        const startDate = moment.utc(props.date);
        const endDate = moment.utc(props.date).add(1, 'days');

        if (props.chart.metric.dataType === 'Integer' || props.chart.metric.dataType === 'Decimal') {
            API.getDataAverage(props.shipId, props.chart.metric.monitor.displayName, props.chart.metric.name, startDate.toDate(), endDate.toDate(), 15).then((data) => {
                let max = props.chart.expectedMax;

                if (Array.isArray(data)) {
                    let formattedData = [];

                    for (let point of data) {
                        if (point.value > max) {
                            max = point.value;
                        }
                        formattedData.push([new Date(point.timestamp).getTime(), point.value])
                    }

                    setData(formattedData);
                } else {
                    setData([]);
                }

                setStartDate(startDate);
                setEndDate(endDate);
                setMax(max);
            })
        } else {
            API.getDataRaw(props.shipId, props.chart.metric.monitor.displayName, props.chart.metric.name, startDate.toDate(), endDate.toDate()).then((data) => {
                if (Array.isArray(data)) {
                    let formattedData = [];

                    for (let point of data) {
                        formattedData.push([new Date(point.timestamp).getTime(), point.value])
                    }

                    setData(formattedData);
                } else {
                    setData([]);
                }

                setStartDate(startDate);
                setEndDate(endDate);
            })
        }
    }

    function updateChartData30D() {
        const startDate = moment.utc(props.date);
        const endDate = moment.utc(props.date).add(30, 'days');

        if (props.chart.metric.dataType === 'Integer' || props.chart.metric.dataType === 'Decimal') {
            API.getDataAverage(props.shipId, props.chart.metric.monitor.displayName, props.chart.metric.name, startDate.toDate(), endDate.toDate(), 720).then((data) => {
                let max = props.chart.expectedMax;

                if (Array.isArray(data)) {
                    let formattedData = [];

                    for (let point of data) {
                        if (point.value > max) {
                            max = point.value;
                        }
                        formattedData.push([new Date(point.timestamp).getTime(), point.value])
                    }

                    setData(formattedData);
                } else {
                    setData([]);
                }

                setStartDate(startDate);
                setEndDate(endDate);
                setMax(max);
            })
        } else {
            API.getDataRaw(props.shipId, props.chart.metric.monitor.displayName, props.chart.metric.name, startDate.toDate(), endDate.toDate()).then((data) => {
                if (Array.isArray(data)) {
                    let formattedData = [];

                    for (let point of data) {
                        formattedData.push([new Date(point.timestamp).getTime(), point.value])
                    }

                    setData(formattedData);
                } else {
                    setData([]);
                }

                setStartDate(startDate);
                setEndDate(endDate);
            })
        }
    }

    return (
        <Card>
            <CardHeader title={props.chart.caption} />
            {
                props.chart.type === "LINE" &&
                    <LineChart data={data} startDate={startDate} endDate={endDate} max={max} unit={props.chart.metric.info.unit} color={props.chart.color}/>
            }
            {
                props.chart.type === "MAP" &&
                    <MapChart data={data}/>
            }
        </Card>
    )
}