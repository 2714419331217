/**
 * Created by Robin on 15/04/2023.
 */

import React, {useContext, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";

import moment from 'moment';

import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'
import L from 'leaflet';

import LightGreenCircle from '../img/light-green-circle.png';
import DarkGreenCircle from '../img/dark-green-circle.png';
import OrangeCircle from '../img/orange-circle.png';
import RedCircle from '../img/red-circle.png';

function ShipMapMarker(props) {
    const navigate = useNavigate();

    const createIcon = () => {
        const date = moment(props.timestamp);

        let icon = LightGreenCircle;

        if (date < moment().subtract(1, 'day')) {
            icon = RedCircle;
        } else if (date < moment().subtract(1, 'hour')) {
            icon = OrangeCircle;
        } else if (date < moment().subtract(10, 'minutes')) {
            icon = DarkGreenCircle;
        }

        return new L.Icon({
            iconUrl: icon,
            iconRetinaUrl: icon,
            popupAnchor:  [-0, -0],
            iconSize: [9, 9],
        });
    };

    return (
        <Marker position={props.position} icon={createIcon()} eventHandlers={{
            mouseover: function(e) {
                this.openPopup();
            },
            mouseout: function(e) {
                this.closePopup();
            },
            click: function(e) {
                navigate("/insight/" + props.ship.id);
            }
        }}>
            <Popup>
                {props.children}
            </Popup>
        </Marker>
    );
}

export default ShipMapMarker