/**
 * Created by Robin on 26/07/2023.
 */

import React, {useContext, useEffect, useState} from "react";
import { useParams, useNavigate, Link } from 'react-router-dom';

import APIContext from '../../context/APIContext.js';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';

import MetricNameSelector from "../../components/MetricNameSelector";
import ShipMetricSelector from "../../components/ShipMetricSelector";

export default function ChartAdd(props) {
    const API = useContext(APIContext);
    const navigate = useNavigate();
    const [ship, setShip] = useState(null);

    const [caption, setCaption] = useState("");
    const [type, setType] = useState("");
    const [metricId, setMetricId] = useState("");
    const [expectedMax, setExpectedMax] = useState(0);
    const [color, setColor] = useState("#29b0ff");

    let {id} = useParams();

    useEffect(() => {
        API.getShip(id).then((ship) => {
            setShip(ship);
        });
    }, [API]);

    const IsValid = () => {
        if (caption.length < 3) {
            return false;
        }

        if (metricId === "") {
            return false;
        }

        if (!(["LINE", "MAP"].includes(type))) {
            return false;
        }

        return true;
    };

    const AddChart = () => {
        API.createShipChart(id, {
            caption,
            type,
            metricId,
            expectedMax,
            color
        }).then((chart) => {
            navigate(-1);
        });
    };

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                style={{marginBottom: '20px'}}
                spacing="10px"
            >
                <Grid item>
                    <IconButton size="small" variant="text" onClick={() => navigate(-1)}>
                        <ArrowBackIcon/>
                    </IconButton >
                </Grid>
                <Grid item>
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <Typography variant="h5" component="h2">
                            <b>Add chart: </b>{ship ? ship.name : ''}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Chart details" />
                        <CardContent>
                            <TextField fullWidth label="Caption" style={{margin: '10px 0px'}} variant="outlined" value={caption} onChange={(e) => setCaption(e.target.value)}/>
                            <ShipMetricSelector shipId={id} metricId={metricId} onMetricIdChange={(id) => setMetricId(id)}/>
                            <TextField select fullWidth label="Type" style={{margin: '10px 0px'}} variant="outlined" value={type} onChange={(e) => setType(e.target.value)}>
                                <MenuItem value={"LINE"}>Line</MenuItem>
                                <MenuItem value={"MAP"}>Map</MenuItem>
                            </TextField>
                            <TextField fullWidth type="number" label="Expected maximum value" style={{margin: '10px 0px'}} variant="outlined" value={expectedMax} onChange={(e) => setExpectedMax(e.target.value)}/>
                            <TextField select fullWidth label="Type" style={{margin: '10px 0px'}} variant="outlined" value={color} onChange={(e) => setColor(e.target.value)}>
                                <MenuItem value={"#29b0ff"}>Blue</MenuItem>
                                <MenuItem value={"#fa0a0a"}>Red</MenuItem>
                                <MenuItem value={"#0be041"}>Green</MenuItem>
                                <MenuItem value={"#f5f100"}>Yellow</MenuItem>
                                <MenuItem value={"#ff9500"}>Orange</MenuItem>
                                <MenuItem value={"#a200ff"}>Purple</MenuItem>
                            </TextField>
                            <Grid item align="right">
                                <Button variant="outlined" disabled={!IsValid()} onClick={() => AddChart()}>Create</Button>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}