/**
 * Created by Robin on 04/01/2023.
 */

import React from "react";

import Button from '@mui/material/Button';

import {Link} from "react-router-dom";

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

function UserButton(props) {

    if (props.user === undefined || props.user === null || props.user.id === null) {
        return [];
    } else {
        return (
            <Button variant="text" style={{textTransform: 'none'}} startIcon={<AccountCircleIcon />} component={Link} to={"/user/" + props.user.id}>
                {props.user.displayName}
            </Button>
        );
    }
}

export default UserButton;