/**
 * Created by Robin on 07/01/2023.
 */

import React from "react";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function ConfirmDeleteDialog(props) {

    const handleClose = () => {
        if (typeof props.onClose === 'function') {
            props.onClose();
        }
    };

    const handleDelete = () => {
        if (typeof props.onDelete === 'function') {
            props.onDelete();
        }

        handleClose();
    };

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            aria-labelledby="confirm-delete-dialog-title"
            aria-describedby="confirm-delete-dialog-description"
        >
            <DialogTitle id="confirm-delete-dialog-title">
                Confirmation
            </DialogTitle>
            <DialogContent>
                {/*<DialogContentText id="confirm-delete-dialog-description">*/}
                    {props.children}
                {/*</DialogContentText>*/}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDelete} color="error">{props.deleteText ?? "Delete"}</Button>
                <Button onClick={handleClose} autoFocus>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDeleteDialog;