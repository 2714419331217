/**
 * Created by Robin on 04/01/2023.
 */

import React, {useContext, useEffect, useState, useCallback} from "react";

import Skeleton from '@mui/material/Skeleton';

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import APIContext from './../context/APIContext.js';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import ListCardHeader from './../components/ListCardHeader.js';
import MetricButton from './../components/MetricButton.js';
import ShipButton from './../components/ShipButton.js';
import GroupButton from './../components/GroupButton.js';
import AlarmDefinitionButton from './../components/AlarmDefinitionButton.js'

function AlarmDefinitionList(props) {
    const API = useContext(APIContext);
    const [alarmDefinitions, setAlarmDefinitions] = useState(false);

    const reload = useCallback(() => {
        API.getAlarmDefinitions().then((definitions) => {
            if (definitions !== false) {
                setAlarmDefinitions(definitions);
                console.log(definitions);
            }
        });
    }, [API]);

    useEffect(() => {
        reload();
    }, [reload]);


    const AlarmDefinitionCard = () => {
        if (alarmDefinitions === false) {
            return (
                <Card>
                    <ListCardHeader title="Alarm definition list" createLink="/alarmdefinition/create" />
                    <CardContent>
                        <Skeleton variant="rounded" height={20} width="50%" animation="pulse"/>
                        <br />
                        <Skeleton variant="rounded" height={20} width="25%" animation="pulse"/>
                        <br />
                        <Skeleton variant="rounded" height={20} width="75%" animation="pulse"/>
                        <br />
                        <Skeleton variant="rounded" height={20} width="50%" animation="pulse"/>
                    </CardContent>
                </Card>
            );
        } else {
            return (
                <Card>
                    <ListCardHeader title="Alarm definition list" createLink="/alarmdefinition/create" />
                    <CardContent sx={{
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingBottom: '0px !important'
                    }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Target</TableCell>
                                    <TableCell>Metric</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {alarmDefinitions.map((definition) => (
                                    <TableRow
                                        key={definition.id}

                                    >
                                        <TableCell><AlarmDefinitionButton alarmDefinition={definition}/></TableCell>
                                        <TableCell>{definition.ship.id !== null ? <ShipButton ship={definition.ship} /> : <GroupButton group={definition.group} />}</TableCell>
                                        <TableCell>{definition.monitor_name + " - " + definition.metric_name}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            );
        }
    }
    return <Grid container spacing={24}>
        <Grid item xs={12} sm={12}>
            <AlarmDefinitionCard />
        </Grid>
    </Grid>
}

export default AlarmDefinitionList;