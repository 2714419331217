/**
 * Created by Robin on 18/12/2022.
 */

import React, {useContext, useEffect, useState, useRef} from "react";
import { useParams, useNavigate } from 'react-router-dom';

import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';

import Dropzone from 'react-dropzone'

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import APIContext from './../context/APIContext.js';

import InvalidResource from './../components/InvalidResource.js';
import ShipSelector from './../components/ShipSelector.js';
import UserSelector from './../components/UserSelector.js';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

import AddIcon from '@mui/icons-material/Add';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SendIcon from '@mui/icons-material/Send';
import SaveIcon from '@mui/icons-material/Save';

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';

import ReactTimeAgo from 'react-time-ago';
import TimeAgoTooltipWrapper from './../components/TimeAgoTooltipWrapper.js';

import TicketTimelineAction from './../components/TicketTimelineAction.js';

TimeAgo.addDefaultLocale(en);

function TicketView(props) {
    const API = useContext(APIContext);
    const [ticket, setTicket] = useState(null);
    const navigate = useNavigate();

    const [title, setTitle] = useState('');
    const [status, setStatus] = useState('');
    const [priority, setPriority] = useState('');
    const [shipId, setShipId] = useState(null);
    const [assigneeUserId, setAssigneeUserId] = useState(null);

    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadingFiles, setUploadingFiles] = useState([]);

    const [updatePending, setUpdatePending] = useState(false);
    const [createPostPending, setCreatePostPending] = useState(false);

    const editorRef = useRef(null);

    let { id } = useParams();

    useEffect(() => {
        API.getTicket(id).then((ticketData) => {
            setTicket(ticketData);
        });

    }, [id, API]);

    useEffect(() => {
        if (ticket) {
            setTitle(ticket.title);
            setStatus(ticket.status);
            setPriority(ticket.priority);
            setShipId(ticket.ship !== null ? ticket.ship.id : null);
            setAssigneeUserId(ticket.assigneeUser !== null ? ticket.assigneeUser.id : null);
        }
    }, [ticket]);

    const renderFileUploads = () => {
        let ret = [];

        Object.keys(uploadedFiles).forEach((fileName) => {
            ret.push(<li key={'DONE_' + fileName}>
                {fileName}
            </li>);
        });

        Object.keys(uploadingFiles).forEach((fileName) => {
            ret.push(<li key={'UPL_' + fileName}>
                {fileName} ({Math.round(uploadingFiles[fileName] * 100)}%)
            </li>);
        });

        if (ret.length > 0) {
            return (<ul>{ret}</ul>);
        } else {
            return [];
        }
    };

    const onFilesDropped = (files) => {
        files.forEach((file) => {
            onFileDropped(file);
        });
    };

    const onFileDropped = async (file) => {
        const name = file.name;

        if (name in uploadingFiles || name in uploadedFiles) {
            alert("File already exists: " + name);
            return;
        }

        setUploadingFiles({...uploadingFiles, [name]: 0.0});

        const res = await API.uploadFile(file, (e) => {
            setUploadingFiles({...uploadingFiles, [name]: e.progress});
        });

        if (res === false || res.status !== 'success') {
            let tmp = {...uploadingFiles};
            delete tmp[name];
            setUploadingFiles(tmp);

            alert("Failed to upload file " + name);
        } else {
            let tmp = {...uploadingFiles};
            delete tmp[name];
            setUploadingFiles(tmp);

            setUploadedFiles({...uploadedFiles, [name]: res.hash});
        }
    };

    const updateTicket = () => {
        const data = {
            title: title,
            status: status,
            priority: priority,
            shipId: shipId,
            assigneeUserId: assigneeUserId
        };

        setUpdatePending(true);

        API.setTicket(id, data).then((res) => {
            API.getTicket(id).then((ticketData) => {
                setTicket(ticketData);
                setUpdatePending(false);
            });
        })
    };

    const createPost = () => {
        if (uploadingFiles.length > 0) {
            alert("File upload is still in progress. Please wait for it to finish");
            return;
        }

        setCreatePostPending(true);

        const content = editorRef.current.getInstance().getMarkdown();

        let attachments = [];
        Object.keys(uploadedFiles).forEach((fileName) => {
           attachments.push({filename: fileName, hash: uploadedFiles[fileName]});
        });

        API.createTicketPost(id, content, attachments).then(() => {
            API.getTicket(id).then((ticketData) => {
                setTicket(ticketData);

                editorRef.current.getInstance().setMarkdown(" ");

                setUploadedFiles([]);

                setCreatePostPending(false);
            });
        });
    };

    const TimelineContentBox = (props) => {
        let items = [];

        let index = 0;
        if (Array.isArray(props.children)) {
            for (let child of props.children) {
                items.push(<Grid key={index++} item>{child}</Grid>)
            }
        } else {
            items.push(<Grid key={index++} item>{props.children}</Grid>)
        }

        return (
            <Grid
                style={{ height: 66 }}
                spacing={'5px'}
                container
                direction="row"
                justifyContent={props.position === 'left' ? 'flex-end' : 'flex-start'}
                alignItems="center"
            >

                {items}
            </Grid>
        )
    };

    if (ticket === false) {
        return (
            <InvalidResource type="ticket" id={id}/>
        )
    } else if (ticket === null) {
        return <p>Loading...</p>;
    } else {
        let actionElements = [];

        for (const action of ticket.ticket_actions) {
            actionElements.push(<TicketTimelineAction key={JSON.stringify(action)} action={action} />);
        }

        return (
            <React.Fragment>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{marginBottom: '20px'}}
                    spacing="10px"
                >
                    <Grid item>
                        <IconButton size="small" variant="text" onClick={() => navigate(-1)}>
                            <ArrowBackIcon/>
                        </IconButton >
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <Typography variant="h5" component="h2">
                                <b>[#{ticket.id}]</b> {ticket.title}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Card>
                    <CardHeader title={
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography variant="h6">
                                Ticket details
                            </Typography>
                            <LoadingButton loading={updatePending} variant="contained" startIcon={<SaveIcon />} onClick={updateTicket}>
                                Save
                            </LoadingButton>
                        </Grid>
                    } />
                    <CardContent>
                        <TextField fullWidth style={{margin: '10px 0px'}} label="Title" variant="outlined" value={title} onChange={(e) => setTitle(e.target.value)}/>
                        <Grid container spacing={{md: 8, xs: 0}}>
                            <Grid item xs={12} md={6}>
                                <TextField select fullWidth style={{margin: '10px 0px'}} value={status} variant="outlined" label="Status" onChange={(e) => setStatus(e.target.value)}>
                                    <MenuItem value={'OPEN'}>Open</MenuItem>
                                    <MenuItem value={'HOLD'}>On hold</MenuItem>
                                    <MenuItem value={'CLOSED'}>Closed</MenuItem>
                                </TextField>
                                <TextField select fullWidth style={{margin: '10px 0px'}} value={priority} variant="outlined" label="Priority" onChange={(e) => setPriority(e.target.value)}>
                                    <MenuItem value={'HIGH'}>High</MenuItem>
                                    <MenuItem value={'NORMAL'}>Normal</MenuItem>
                                    <MenuItem value={'LOW'}>Low</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ShipSelector value={shipId} onChange={(e) => setShipId(e.target.value)}/>
                                <UserSelector value={assigneeUserId} label="Assignee" onChange={(e) => setAssigneeUserId(e.target.value)}/>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                <Timeline>
                    <TimelineItem>
                        <TimelineOppositeContent color="textSecondary" sx={{ py: '0px', px: 1 }}>
                            <TimelineContentBox position="left">
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="flex-end">
                                    <Button size="small" variant="text" style={{textTransform: 'none'}} startIcon={<AccountCircleIcon />}>{ticket.creatorUser.displayName}</Button>
                                    <Typography style={{paddingRight: '5px', fontSize: '12px'}}>
                                        <ReactTimeAgo
                                            date={new Date(ticket.createdAt)}
                                            locale="en-US"
                                            timeStyle="round"
                                            wrapperComponent={TimeAgoTooltipWrapper}/>
                                    </Typography>
                                </Grid>
                            </TimelineContentBox>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot color="primary">
                                <AddIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '0px', px: 1 }}>
                            <TimelineContentBox>
                                <Typography style={{fontSize: '14px'}}>Created ticket</Typography>
                            </TimelineContentBox>
                        </TimelineContent>
                    </TimelineItem>
                    {actionElements}
                </Timeline>

                <Editor
                    usageStatistics={false}
                    initialValue=" "
                    previewStyle="vertical"
                    height="400px"
                    initialEditType="wysiwyg"
                    useCommandShortcut={true}
                    ref={editorRef}
                    autofocus={false}
                    toolbarItems={[
                        ['heading', 'bold', 'italic', 'strike'],
                        ['hr', 'quote'],
                        ['ul', 'ol'],
                        ['table', 'link'],
                        ['code', 'codeblock'],
                        ['scrollSync'],
                    ]}
                />

                <br />
                <Grid
                    container
                    justifyContent="space-between" alignItems="flex-start">
                    <Grid item xs={12} md={6}>
                        {renderFileUploads()}
                        <Dropzone onDrop={acceptedFiles => onFilesDropped(acceptedFiles)}>
                            {({getRootProps, getInputProps, isDragActive}) => (
                                <React.Fragment>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        {
                                            isDragActive ?
                                                <Button variant="contained" startIcon={<UploadFileIcon />}>
                                                    Add Attachment
                                                </Button> :
                                                <Button variant="outlined" startIcon={<UploadFileIcon />}>
                                                    Add Attachment
                                                </Button>
                                        }
                                    </div>
                                </React.Fragment>
                            )}
                        </Dropzone>
                    </Grid>
                    <Grid item container justifyContent="flex-end" alignItems="flex-end" xs={12} md={6}>
                        <LoadingButton loading={createPostPending} variant="contained" startIcon={<SendIcon />} onClick={createPost}>Create Post</LoadingButton>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

export default TicketView;