/**
 * Created by Robin on 17/12/2022.
 */

import React, {useState} from "react";
import axios from "axios";
import Cookies from "js-cookie";

import APIContext from './../context/APIContext.js';

function API(props) {
    const URL = "https://api.shiptrack.app/";

    const [email, setEmailState] = useState("");
    const [password, setPasswordState] = useState("");
    const [user, setUser] = useState(null);

    const setEmail = (value) => {
        setEmailState(value);
        setUser(null);
    };

    const setPassword = (value) => {
        setPasswordState(value);
        setUser(null);
    };

    const isLoggedIn = () => {
        return user !== null;
    };

    const getAlarmDefinitions = async () => {
        const res = await _get("alarmdefinition/");
        if (res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const getAlarmDefinition = async (id) => {
        const res = await _get("alarmdefinition/" + id);
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const setAlarmDefinition = async (id, data) => {
        const res = await _patch("alarmdefinition/" + id, data);

        if (res === undefined || res === false){
            return false;
        } else {
            return true;
        }
    };

    const deleteAlarmDefinition = async (definitionId) => {
        const res = await _delete("alarmdefinition/" + definitionId);

        if (res === undefined || res === false || res.data.status !== 'success'){
            return false;
        } else {
            return true;
        }
    };

    const createAlarmDefinition = async (data) => {
        const res = await _post("alarmdefinition", data);

        if (res === undefined || res === false || res.data.status !== 'success'){
            return false;
        } else {
            return res.data.data;
        }
    };

    const deleteGroup = async (groupId) => {
        const res = await _delete("group/any/" + groupId);

        if (res === undefined || res === false || res.data.status !== 'success'){
            return false;
        } else {
            return true;
        }
    };

    const addUserToGroup = async (groupId, userId) => {
        const res = await _post("group/any/" + groupId + "/user", {userId: userId});

        if (res === undefined || res === false || res.data.status !== 'success'){
            return false;
        } else {
            return true;
        }
    };

    const addShipToGroup = async (groupId, shipId) => {
        const res = await _post("group/any/" + groupId + "/ship", {shipId: shipId});

        if (res === undefined || res === false || res.data.status !== 'success'){
            return false;
        } else {
            return true;
        }
    };

    const removeUserFromGroup = async (groupId, userId) => {
        const res = await _delete("group/any/" + groupId + "/user/" + userId);

        if (res === undefined || res === false || res.data.status !== 'success'){
            return false;
        } else {
            return true;
        }
    };

    const removeShipFromGroup = async (groupId, shipId) => {
        const res = await _delete("group/any/" + groupId + "/ship/" + shipId);

        if (res === undefined || res === false || res.data.status !== 'success'){
            return false;
        } else {
            return true;
        }
    };

    const getGroupCategories = async () => {
        const res = await _get("group/");
        if (res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const createGroupCategory = async (data) => {
        const res = await _post("group", data);

        if (res === undefined || res === false || res.data.status !== 'success'){
            return false;
        } else {
            return res.data.data;
        }
    };

    const deleteGroupCategory = async (categoryId) => {
        const res = await _delete("group/" + categoryId);

        if (res === undefined || res === false || res.data.status !== 'success'){
            return false;
        } else {
            return true;
        }
    };

    const getGroup = async (groupId) => {
        const res = await _get("group/any/" + groupId);
        if (res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const createGroup = async (categoryId, data) => {
        const res = await _post("group/" + categoryId, data);

        if (res === undefined || res === false || res.data.status !== 'success'){
            return false;
        } else {
            return res.data.data;
        }
    };

    const getMetrics = async () => {
        const res = await _get("metric/");
        if (res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const getMetricParsers = async () => {
        const res = await _get("metric/parser");
        if (res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const getMetric = async (key) => {
        const res = await _get("metric/" + key);
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const setMetric = async (key, data) => {
        const res = await _patch("metric/" + key, data);

        if (res === undefined || res === false){
            return false;
        } else {
            return true;
        }
    };

    const createMetric = async (data) => {
        const res = await _post("metric", data);

        if (res === undefined || res === false || res.data.status !== 'success'){
            return false;
        } else {
            return res.data.data;
        }
    };

    const deleteMetric = async (key) => {
        const res = await _delete("metric/" + key);
        if (res === undefined || res === false){
            return false;
        } else {
            return true;
        }
    };

    const getDevices = async () => {
        const res = await _get("device/");
        if (res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const getDevice = async (id) => {
        const res = await _get("device/" + id);
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const setDevice = async (id, data) => {
        const res = await _patch("device/" + id, data);

        if (res === undefined || res === false){
            return false;
        } else {
            return true;
        }
    };

    const createDevice = async (data) => {
        const res = await _post("device", data);

        if (res === undefined || res === false || res.data.status !== 'success'){
            return false;
        } else {
            return res.data.data;
        }
    };

    const deleteDevice = async (id) => {
        const res = await _delete("device/" + id);
        if (res === undefined || res === false){
            return false;
        } else {
            return true;
        }
    };

    const getTickets = async () => {
        const res = await _get("ticket/");
        if (res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const getShips = async () => {
        const res = await _get("ship/");
        if (res === false) {
            return false;
        } else {
            return res.data.data;
        }
    };

    const getShip = async (id) => {
        const res = await _get("ship/" + id);
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const setShip = async (id, data) => {
        const res = await _patch("ship/" + id, data);

        if (res === undefined || res === false){
            return false;
        } else {
            return true;
        }
    };

    const createShip = async (data) => {
        const res = await _post("ship", data);

        if (res === undefined || res === false || res.data.status !== 'success'){
            return false;
        } else {
            return res.data.data;
        }
    };

    const deleteShip = async (id) => {
        const res = await _delete("ship/" + id);
        if (res === undefined || res === false){
            return false;
        } else {
            return true;
        }
    };

    const getUsers = async () => {
        const res = await _get("user/");
        if (res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const getUserInfo = async (id) => {
        const res = await _get("user/" + id);
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const getUserSelf = async () => {
        const res = await _get("user/self");
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const deleteUser = async (id) => {
        const res = await _delete("user/" + id);
        if (res === undefined || res === false){
            return false;
        } else {
            return true;
        }
    };

    const createUser = async (data) => {
        const res = await _post("user", data);

        if (res === undefined || res === false || res.data.status !== 'success'){
            return false;
        } else {
            return res.data.data;
        }
    };

    const setUserInfo = async (id, data) => {
        const res = await _patch("user/" + id, data);

        if (res === undefined || res === false){
            return false;
        } else {
            return true;
        }
    };

    const getTicket = async (id) => {
        const res = await _get("ticket/" + id);
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const setTicket = async (id, data) => {
        const res = await _patch("ticket/" + id, data);
        if (res === undefined || res === false){
            return false;
        } else {
            return true;
        }
    };

    const getShipCharts = async (shipId) => {
        const res = await _get("ship/" + shipId + "/chart");
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const getShipChart = async (shipId, chartId) => {
        const res = await _get("ship/" + shipId + "/chart/" + chartId);
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const updateShipChart = async (shipId, chartId, data) => {
        const res = await _patch("ship/" + shipId + "/chart/" + chartId, data);

        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const createShipChart = async (shipId, data) => {
        const res = await _post("ship/" + shipId + "/chart", data);

        if (res === undefined || res === false || res.data.status !== 'success'){
            return false;
        } else {
            return res.data.data;
        }
    };

    const deleteShipChart = async (shipId, chartId) => {
        const res = await _delete("ship/" + shipId + "/chart/" + chartId);
        if (res === undefined || res === false){
            return false;
        } else {
            return true;
        }
    };

    const moveShipChartUp = async (shipId, chartId) => {
        const res = await _get("ship/" + shipId + "/chart/" + chartId + "/up");
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const moveShipChartDown = async (shipId, chartId) => {
        const res = await _get("ship/" + shipId + "/chart/" + chartId + "/down");
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const getAvailableParsers = async (shipId) => {
        const res = await _get("ship/" + shipId + "/parsers");
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const getShipOpenAlarms = async (shipId) => {
        const res = await _get("ship/" + shipId + "/alarm/open");
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const getShipAlarms = async (shipId) => {
        const res = await _get("ship/" + shipId + "/alarm");
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const closeAlarm = async (alarmId) => {
        const res = await _get("alarm/" + alarmId + "/close");
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const getShipMonitor = async (shipId, monitorId) => {
        const res = await _get("ship/" + shipId + "/monitors/" + monitorId);
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const getShipMonitors = async (shipId) => {
        const res = await _get("ship/" + shipId + "/monitors");
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const getGroupMonitors = async (groupId) => {
        const res = await _get("group/0/" + groupId + "/monitors");
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const createShipMonitor = async (shipId, name, parser, data) => {
        const res = await _post("ship/" + shipId + "/monitors", {name, parser, data});

        if (res === undefined || res === false){
            return false;
        } else {
            return true;
        }
    };

    const updateShipMonitor = async (shipId, monitorId, data) => {
        const res = await _patch("ship/" + shipId + "/monitors/" + monitorId, data);

        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const deleteShipMonitor = async (shipId, monitorId) => {
        const res = await _delete("ship/" + shipId + "/monitors/" + monitorId);

        if (res === undefined || res === false){
            return false;
        } else {
            return true;
        }
    };

    const getAllOpenAlarms = async () => {
        const res = await _get("alarm/open");
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const getVolumeMaps = async () => {
        const res = await _get("map/");
        if (res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const getVolumeMap = async (id) => {
        const res = await _get("map/" + id);
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const createVolumeMap = async (data) => {
        const res = await _post("map", data);

        if (res === undefined || res === false || res.data.status !== 'success'){
            return false;
        } else {
            return res.data.data;
        }
    };

    const deleteVolumeMap = async (key) => {
        const res = await _delete("map/" + key);
        if (res === undefined || res === false){
            return false;
        } else {
            return true;
        }
    };

    const setVolumeMap = async (id, data) => {
        const res = await _patch("map/" + id, data);

        if (res === undefined || res === false){
            return false;
        } else {
            return true;
        }
    };

    const getVolumeMapEntries = async (id) => {
        const res = await _get("map/" + id + "/entry");
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const deleteVolumeMapEntry = async (mapId, entryId) => {
        const res = await _delete("map/" + mapId + "/entry/" + entryId);
        if (res === undefined || res === false){
            return false;
        } else {
            return true;
        }
    };

    const createVolumeMapEntry = async (mapId, data) => {
        const res = await _post("map/" + mapId + "/entry", data);

        if (res === undefined || res === false || res.data.status !== 'success'){
            return false;
        } else {
            return res.data.data;
        }
    };

    const getRawData = async (type, shipId, key, startTimestamp, endTimestamp) => {
        const res = await _get("data/raw/" + type + "/" + shipId + "/" + key + "/" + startTimestamp + "/" + endTimestamp);
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const getLatestData = async (type, shipId, key) => {
        const res = await _get("data/latest/" + type + "/" + shipId + "/" + key);
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const getDataLatest = async (shipId, monitorName, metricName) => {
        const res = await _get("data/" + shipId + "/" + encodeURIComponent(monitorName) + "/" + encodeURIComponent(metricName) + "/latest");
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const getDataRaw = async (shipId, monitorName, metricName, start, end) => {
        const res = await _get("data/" + shipId + "/" + encodeURIComponent(monitorName) + "/" + encodeURIComponent(metricName) + "/raw/" + start.toISOString() + "/" + end.toISOString());
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const getDataAverage = async (shipId, monitorName, metricName, start, end, avg) => {
        const res = await _get("data/" + shipId + "/" + encodeURIComponent(monitorName) + "/" + encodeURIComponent(metricName) + "/avg/" + start.toISOString() + "/" + end.toISOString() + "/" + encodeURIComponent(avg));
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data.data;
        }
    };

    const createTicketPost = async (id, content, attachments = []) => {
        const res = await _post("ticket/" + id, {content: content, attachments: attachments});

        if (res === undefined || res === false){
            return false;
        } else {
            return true;
        }
    };

    const authenticate = async (persistent, authEmail, authPassword) => {
        const res = await _get("user/self/", {}, authEmail, authPassword);
        let success = false;
        let cookieOptions = {};

        if (persistent) {
            cookieOptions.expires = 3650; // 10 years
        }

        if (res !== false && res.data.status === 'success') {
            success = true;
        }

        if (success) {
            if (authEmail) {
                setEmail(authEmail);
            }

            if (authPassword) {
                setPassword(authPassword);
            }

            setUser(res.data.data);

            Cookies.set('email', (authEmail ?? email), cookieOptions);
            Cookies.set('password', (authPassword ?? password), cookieOptions);
            if (persistent) {
                Cookies.set('rememberme', '1', cookieOptions);
            } else {
                Cookies.remove('rememberme');
            }
        } else {
            setUser(null);
            Cookies.remove('email');
            Cookies.remove('password');
            Cookies.remove('rememberme');
        }

        return success;
    };

    const logoff = () => {
        console.log("logoff");
        setUser(null);
        Cookies.remove('email');
        Cookies.remove('password');
        Cookies.remove('rememberme');
    };

    const uploadFile = async (file, progressCallback) => {
        const res = await _upload("file/", file, progressCallback);
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data;
        }
    };

    const downloadFile = async (hash) => {
        const res = await _download("file/" + hash);
        if (res === undefined || res === false){
            return false;
        } else {
            return res.data;
        }
    };

    const _get = async (path, params = {}, authEmail, authPassword) => {
        try {
            const res = await axios({
                    method: 'get',
                    url: URL + path,
                    params: params,
                    headers: {
                        Authorization: "Basic " + btoa((authEmail ?? email) + ":" + (authPassword ?? password))
                    }
                }
            );

            return res;
        } catch (ex) {
            if (ex.response?.status === 401) {
                return false;
            }
        }
    };

    const _delete = async (path, params = {}) => {
        try {
            const res = await axios({
                    method: 'delete',
                    url: URL + path,
                    params: params,
                    headers: {
                        Authorization: "Basic " + btoa(email + ":" + password)
                    }
                }
            );

            return res;
        } catch (ex) {
            if (ex.response?.status === 401) {
                return false;
            }
        }
    };

    const _post = async (path, data = {}, params = {}) => {
        try {
            const res = await axios({
                    method: 'post',
                    url: URL + path,
                    params: params,
                    data: data,
                    headers: {
                        Authorization: "Basic " + btoa(email + ":" + password)
                    }
                }
            );

            return res;
        } catch (ex) {
            if (ex.response?.status === 401) {
                return false;
            }
        }
    };

    const _download = async (path, params = {}) => {
        try {
            const res = await axios({
                    responseType: 'arraybuffer',
                    method: 'get',
                    url: URL + path,
                    params: params,
                    headers: {
                        Authorization: "Basic " + btoa(email + ":" + password)
                    }
                }
            );

            return res;
        } catch (ex) {
            if (ex.response?.status === 401 || ex.response?.status === 404) {
                return false;
            }
        }
    };

    const _upload = async (path, file, onUploadProgress) => {
        try {
            const formData = new FormData();

            formData.append("file", file);

            const res = await axios({
                    method: 'post',
                    url: URL + path,
                    data: formData,
                    headers: {
                        Authorization: "Basic " + btoa(email + ":" + password),
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: onUploadProgress
                }
            );

            return res;
        } catch (ex) {
            if (ex.response?.status === 401) {
                return false;
            }
        }
    };

    const _patch = async (path, data = {} ,params = {}) => {
        try {
            const res = await axios({
                    method: 'patch',
                    url: URL + path,
                    params: params,
                    data: data,
                    headers: {
                        Authorization: "Basic " + btoa(email + ":" + password)
                    }
                }
            );

            return res;
        } catch (ex) {
            if (ex.response?.status === 401) {
                return false;
            }
        }
    };

    return (
        <APIContext.Provider value={{
            email: email,
            password: password,
            user: user,

            setEmail: setEmail,
            setPassword: setPassword,
            isLoggedIn: isLoggedIn,
            authenticate: authenticate,
            logoff: logoff,

            getTickets: getTickets,
            getTicket: getTicket,
            setTicket: setTicket,
            createTicketPost: createTicketPost,

            uploadFile: uploadFile,
            downloadFile: downloadFile,

            getDevices: getDevices,
            getDevice: getDevice,
            setDevice: setDevice,
            createDevice: createDevice,
            deleteDevice: deleteDevice,

            getShips: getShips,
            getShip: getShip,
            setShip: setShip,
            createShip: createShip,
            deleteShip: deleteShip,

            getUsers: getUsers,
            getUserSelf: getUserSelf,
            getUser: getUserInfo,
            setUser: setUserInfo,
            createUser: createUser,
            deleteUser: deleteUser,

            getVolumeMaps: getVolumeMaps,
            getVolumeMap: getVolumeMap,
            createVolumeMap: createVolumeMap,
            deleteVolumeMap: deleteVolumeMap,
            setVolumeMap: setVolumeMap,

            getVolumeMapEntries: getVolumeMapEntries,
            deleteVolumeMapEntry: deleteVolumeMapEntry,
            createVolumeMapEntry: createVolumeMapEntry,

            /*
            getMetrics: getMetrics,
            getMetric: getMetric,
            setMetric: setMetric,
            createMetric: createMetric,
            deleteMetric: deleteMetric,
            */
            getMetricParsers: getMetricParsers,

            getGroupCategories: getGroupCategories,
            createGroupCategory: createGroupCategory,
            deleteGroupCategory: deleteGroupCategory,
            getGroup: getGroup,
            createGroup: createGroup,
            deleteGroup: deleteGroup,
            addUserToGroup: addUserToGroup,
            addShipToGroup: addShipToGroup,
            removeUserFromGroup: removeUserFromGroup,
            removeShipFromGroup: removeShipFromGroup,

            getAlarmDefinitions: getAlarmDefinitions,
            createAlarmDefinition: createAlarmDefinition,
            getAlarmDefinition: getAlarmDefinition,
            setAlarmDefinition: setAlarmDefinition,
            deleteAlarmDefinition: deleteAlarmDefinition,

            getShipMonitors: getShipMonitors,
            getShipMonitor: getShipMonitor,
            getGroupMonitors: getGroupMonitors,
            createShipMonitor: createShipMonitor,
            updateShipMonitor: updateShipMonitor,
            deleteShipMonitor: deleteShipMonitor,

            getShipCharts: getShipCharts,
            getShipChart: getShipChart,
            updateShipChart: updateShipChart,
            createShipChart: createShipChart,
            deleteShipChart: deleteShipChart,
            moveShipChartUp: moveShipChartUp,
            moveShipChartDown: moveShipChartDown,

            getAvailableParsers: getAvailableParsers,

            getShipOpenAlarms: getShipOpenAlarms,
            getShipAlarms: getShipAlarms,
            getAllOpenAlarms: getAllOpenAlarms,
            closeAlarm: closeAlarm,

            //getRawData: getRawData,
            //getLatestData: getLatestData,
            getDataLatest: getDataLatest,
            getDataRaw: getDataRaw,
            getDataAverage: getDataAverage
        }}>
            {props.children}
        </APIContext.Provider>
    );
}

export default API;