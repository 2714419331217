/**
 * Created by Robin on 25/07/2023.
 */

import React, {useContext, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";

import moment from 'moment';

import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'
import L from 'leaflet';

import LightGreenCircle from '../../../../img/light-green-circle.png';


function ShipMarker(props) {
    const navigate = useNavigate();

    const createIcon = () => {
        return new L.Icon({
            iconUrl: LightGreenCircle,
            iconRetinaUrl: LightGreenCircle,
            popupAnchor:  [-0, -0],
            iconSize: [9, 9],
        });
    };

    return (
        <Marker position={props.position} icon={createIcon()} eventHandlers={{
            mouseover: function(e) {
                this.openPopup();
            },
            mouseout: function(e) {
                this.closePopup();
            }
        }}>
            <Popup>
                {props.children}
            </Popup>
        </Marker>
    );
}

export default ShipMarker