/**
 * Created by Robin on 25/07/2023.
 */

import React, {useContext, useEffect, useState} from "react";
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';

import Chart from "react-apexcharts";

const DEFAULT_COLOR = "#29b0ff";

export default function LineChart(props) {

    const formatNumber = (number, digits) => {
        return new Intl.NumberFormat('nl-NL', {
            minimumFractionDigits: digits,
            maximumFractionDigits: digits
        }).format(number);
    };

    const getChartOptions = () => {
        return {
            chart: {
                type: 'area',
                sparkline: {
                    enabled: false
                },
                redrawOnParentResize: true,
                redrawOnWindowResize: true,
                toolbar: {
                    export: {
                        csv: {
                            dateFormatter: (timestamp) => {
                                return moment(timestamp).format("YYYY-MM-DDTHH:mm:ssZ");
                            }
                        }
                    }
                }
            },
            xaxis: {
                type: 'datetime',
                min: props.startDate.toDate().getTime(),
                max: props.endDate.toDate().getTime(),
                labels: {
                    datetimeUTC: false
                },
                tooltip: {
                    enabled: false
                }
            },
            yaxis: {
                min: 0,
                max: props.max,
                labels: {
                    show: true,
                    formatter: (data) => {
                        return formatNumber(data, 2);
                    },
                },
                forceNiceScale: true
            },
            dataLabels: {
                enabled: false
            },
            colors: [props.color ?? DEFAULT_COLOR],
            stroke: {
                curve: 'smooth',
                width: 2
            },
            fill: {
                type: 'solid',
                opacity: 0.3,
            },
            tooltip: {
                followCursor: true,
                fixed: {
                    enabled: false,
                    offsetX: 0
                },
                x: {
                    show: true,
                    format: 'dd MMM yyyy HH:mm:ss'
                },
                y: {
                    formatter: (data) => {
                        return formatNumber(data, 2) + ' ' + (props.unit ?? '');
                    },
                    title: {
                        formatter: function (seriesName) {
                            return ''
                        }
                    }
                },
                style: {
                    zIndex: 9999
                }
            }
        }
    };

    const getChartSeries = () => {
        return [
            {
                name: props.label ? props.label : "data",
                data: props.data
            }
        ]
    };

    if (props.data) {
        return (
            <Chart
                type='area'
                options={getChartOptions()}
                series={getChartSeries()}
                height="250px"
            />
        );
    } else {
        return [];
    }
}