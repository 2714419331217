/**
 * Created by Robin on 19/12/2022.
 */

import React, {useContext, useEffect, useState} from "react";

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';
import ListSubheader from '@mui/material/ListSubheader';

import APIContext from './../context/APIContext.js';

function MetricNameSelector(props) {
    const API = useContext(APIContext);
    const [monitors, setMonitors] = useState(false);

    useEffect(() => {
        if (props.shipId) {
            API.getShipMonitors(props.shipId).then((monitors) => {
                setMonitors(monitors)
            });
        } else if (props.groupId) {
            API.getGroupMonitors(props.groupId).then((monitors) => {
                setMonitors(monitors)
            });
        } else {
            setMonitors(false)
        }

    }, [API, props.shipId, props.groupId]);

    if (monitors === false) {
        return (
            <TextField fullWidth select style={{margin: '10px 0px'}} value="" variant="outlined" label="Monitor" disabled={true}>
                <MenuItem value="">Please select target first</MenuItem>
            </TextField>
        )
    } else {
        let items = [];

        for (const monitor of monitors) {
            if (Object.keys(monitor.metricTypes).length > 0) {
                items.push(<ListSubheader key={monitor.displayName}>{monitor.displayName}</ListSubheader>);
            }

            for (let metricKey of Object.keys(monitor.metricTypes)) {
                const metricType = monitor.metricTypes[metricKey];
                items.push(
                    <MenuItem
                        key={monitor.displayName + '@' + metricKey}
                        value={monitor.displayName + "@" + metricKey}>
                        {metricType.displayName}
                     </MenuItem>
                );
            }
        }

        return (
            <TextField fullWidth select style={{margin: '10px 0px'}} value={props.monitor && props.metric ? props.monitor + "@" + props.metric : ''} variant="outlined" label="Monitor" onChange={(e) => {
                try {
                    let v = e.target.value;

                    const index = v.lastIndexOf("@");
                    const monitor = v.slice(0, index);
                    const metric = v.slice(index + 1);

                    if (typeof props.onMonitorChange === 'function') {
                        props.onMonitorChange(monitor);
                    }
                    if (typeof props.onMetricChange === 'function') {
                        props.onMetricChange(metric);
                    }
                } catch (ex) {
                    if (typeof props.onMonitorChange === 'function') {
                        props.onMonitorChange('');
                    }
                    if (typeof props.onMetricChange === 'function') {
                        props.onMetricChange('');
                    }
                }
            }}>
                {items}
            </TextField>
        )
    }
}

export default MetricNameSelector;