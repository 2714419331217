import * as React from "react";
import {Link} from "react-router-dom";

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import PauseIcon from '@mui/icons-material/Pause';

import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import CircleIcon from '@mui/icons-material/Circle';

import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import MUIDataTable from "mui-datatables";

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';

import ReactTimeAgo from 'react-time-ago';
import TimeAgoTooltipWrapper from './TimeAgoTooltipWrapper.js';


TimeAgo.addDefaultLocale(en);

function TicketTable(props) {
    const ticketList = props.tickets;

    const columns = [
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                filterType: "multiselect",
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    if (ticketList[dataIndex].status === 'CLOSED') {
                        return <Tooltip title="Closed"><StopIcon /></Tooltip>;
                    } else if (ticketList[dataIndex].status === 'HOLD') {
                        return <Tooltip title="On hold"><PauseIcon /></Tooltip>;
                    } else {
                        return <Tooltip title="Open"><PlayArrowIcon /></Tooltip>;
                    }
                }
            }
        },
        {
            name: "priority",
            label: "Prio",
            options: {
                filter: true,
                filterType: "multiselect",
                sort: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    if (ticketList[dataIndex].priority === 'HIGH') {
                        return <Tooltip title="High"><PriorityHighIcon /></Tooltip>;
                    } else if (ticketList[dataIndex].priority === 'LOW') {
                        return <Tooltip title="Low"><LowPriorityIcon /></Tooltip>;
                    } else {
                        return <Tooltip title="Normal"><CircleIcon /></Tooltip>;
                    }
                },
                sortCompare: (order) => {
                    return (a, b) => {
                        if (a.data === 'HIGH') {
                            return (order === 'asc' ? 1 : -1);
                        } else if (a.data === 'LOW') {
                            return (order === 'asc' ? -1 : 1);
                        } else {
                            if (b.data === 'LOW') {
                                return (order === 'asc' ? 1 : -1);
                            } else {
                                return (order === 'asc' ? -1 : 1);
                            }
                        }
                    };
                }
            }
        },
        {
            name: "title",
            label: "Title",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({ style: {
                    width: '100%',
                    maxWidth: '80px'
                }}),
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <Link to={'/ticket/' + ticketList[dataIndex].id}>
                            <Button variant="text" style={{textTransform: 'none'}}>{ticketList[dataIndex].title}</Button>
                        </Link>
                    );
                }
            }
        },
        {
            name: "ship.name",
            label: "Ship",
            options: {
                filter: true,
                filterType: "multiselect",
                sort: true,
                setCellProps: () => ({ style: {
                    whiteSpace: 'nowrap'
                }}),
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    if (ticketList[dataIndex].ship.name === null) {
                        return [];
                    } else {
                        return (
                            <Tooltip title={<span><b>{ticketList[dataIndex].ship.name}</b><br />{ticketList[dataIndex].ship.mmsi !== null ? ticketList[dataIndex].ship.mmsi : ''}</span>}>
                                <Button variant="text" style={{textTransform: 'none'}} startIcon={<DirectionsBoatIcon />}>
                                    {ticketList[dataIndex].ship.name}
                                </Button>
                            </Tooltip>
                        );
                    }
                }
            }
        },
        {
            name: "creatorUser.displayName",
            label: "Creator",
            options: {
                filter: true,
                filterType: "multiselect",
                sort: true,
                setCellProps: () => ({ style: {
                    whiteSpace: 'nowrap'
                }}),
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    if (ticketList[dataIndex].creatorUser.displayName === null) {
                        return [];
                    } else {
                        return (
                            <Tooltip title={
                                <span>
                                    <b>{ticketList[dataIndex].creatorUser.displayName}</b>
                                    <br />
                                    {ticketList[dataIndex].creatorUser.email !== null ? ticketList[dataIndex].creatorUser.email : ''}
                                    <br />
                                    {ticketList[dataIndex].creatorUser.phone !== null ? ticketList[dataIndex].creatorUser.phone : ''}
                                </span>}>
                                <Button variant="text" style={{textTransform: 'none'}} startIcon={<AccountCircleIcon />}>
                                    {ticketList[dataIndex].creatorUser.displayName}
                                </Button>
                            </Tooltip>
                        );
                    }
                }
            }
        },
        {
            name: "assigneeUser.displayName",
            label: "Assigned to",
            options: {
                filter: true,
                filterType: "multiselect",
                sort: true,
                setCellProps: () => ({ style: {
                    whiteSpace: 'nowrap'
                }}),
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    if (ticketList[dataIndex].assigneeUser.displayName === null) {
                        return [];
                    } else {
                        return (
                            <Tooltip title={
                                <span>
                                    <b>{ticketList[dataIndex].assigneeUser.displayName}</b>
                                    <br />
                                    {ticketList[dataIndex].assigneeUser.email !== null ? ticketList[dataIndex].assigneeUser.email : ''}
                                    <br />
                                    {ticketList[dataIndex].assigneeUser.phone !== null ? ticketList[dataIndex].assigneeUser.phone : ''}
                                </span>}>
                                <Button variant="text" style={{textTransform: 'none'}} startIcon={<AccountCircleIcon />}>
                                    {ticketList[dataIndex].assigneeUser.displayName}
                                </Button>
                            </Tooltip>
                        );
                    }
                }
            }
        },
        {
            name: "createdAt",
            label: "Created",
            options: {
                filter: false,
                sort: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return <ReactTimeAgo date={new Date(ticketList[dataIndex].createdAt)} locale="en-US" timeStyle="round" wrapperComponent={TimeAgoTooltipWrapper}/>
                },
                setCellProps: () => ({ style: {
                    whiteSpace: 'nowrap'
                }})
            }
        },
        {
            name: "lastActive",
            label: "Last activity",
            options: {
                filter: false,
                sort: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return <ReactTimeAgo date={new Date(ticketList[dataIndex].lastActive)} locale="en-US" timeStyle="round" wrapperComponent={TimeAgoTooltipWrapper}/>
                },
                setCellProps: () => ({ style: {
                    whiteSpace: 'nowrap'
                }})
            }
        }
    ];

    const options = {
        filterType: 'checkbox',
        enableNestedDataAccess: '.',
        selectableRows: 'none',
        elevation: 0,
        responsive: 'standard',
        onRowClick: (_, indexes, event) => {
            if (event.target.tagName === 'BUTTON') {
                // Ignore the row click if we click a button
                return;
            }

            const row = ticketList[indexes.dataIndex];
        }
    };

    return (
        <React.Fragment>
            <MUIDataTable
                title={""}
                data={ticketList}
                columns={columns}
                options={options}
            />
        </React.Fragment>
    )
}

export default TicketTable;