/**
 * For internal use only
 * Never share this code outside the organization
 * Created by Robin
 * Created on 01/03/2024
 */
import React, {useContext, useEffect, useState} from "react";
import APIContext from "../context/APIContext";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";


export default function ShipMetricSelector(props) {
    const API = useContext(APIContext);

    const [monitors, setMonitors] = useState(false);

    useEffect(() => {
        API.getShipMonitors(props.shipId).then((monitors) => {
            setMonitors(monitors)
        });
    }, []);

    if (monitors === false) {
        return (
            <TextField fullWidth select style={{margin: '10px 0px'}} value="" variant="outlined" label="Metric" disabled={true}>
                <MenuItem value="">Loading...</MenuItem>
            </TextField>
        )
    } else {
        let items = [];

        for (const monitor of monitors) {
            if (monitor.metrics.length > 0) {
                items.push(<ListSubheader key={"mon@" + monitor.id}>{monitor.displayName}</ListSubheader>);
            }

            for (let metric of monitor.metrics) {
                const metricType = monitor.metricTypes[metric.name];
                items.push(
                    <MenuItem
                        key={"met@" + metric.id}
                        value={metric.id}>
                        {metricType.displayName}
                    </MenuItem>
                );
            }
        }

        return (
            <TextField fullWidth select style={{margin: '10px 0px'}} value={props.metricId} variant="outlined" label="Metric" onChange={(e) => {
                let v = e.target.value;

                if (typeof props.onMetricIdChange === 'function') {
                    props.onMetricIdChange(v);
                }
            }}>
                {items}
            </TextField>
        )
    }
}