/**
 * Created by Robin on 06/01/2023.
 */

import React from "react";

import {Link} from "react-router-dom";

import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';


function ListCardHeader(props) {

    const Content = () => {
        return (
            <Grid container spacing={1}>
                {props.deleteCallback && (
                    <Grid item>
                        <Button variant="outlined" startIcon={<DeleteIcon />} color="error" onClick={props.deleteCallback}>
                            {props.deleteLabel ? props.deleteLabel : 'Delete'}
                        </Button>
                    </Grid>
                )}
                {props.createLink && (
                    <Grid item>
                        <Button variant="outlined" startIcon={<AddIcon />} component={Link} to={props.createLink}>
                            {props.createLabel ? props.createLabel : 'Create'}
                        </Button>
                    </Grid>
                )}
                {props.createCallback && (
                    <Grid item>
                        <Button variant="outlined" startIcon={<AddIcon />} onClick={props.createCallback} sx={{whiteSpace: 'nowrap'}}>
                            {props.createLabel ? props.createLabel : 'Create'}
                        </Button>
                    </Grid>
                )}
            </Grid>
        )
    };

    return (
        <CardHeader title={props.title} action={Content()}/>
    )
}

export default ListCardHeader;