/**
 * Created by Robin on 26/07/2023.
 */

import React, {useContext, useEffect, useState} from "react";
import { useParams, useNavigate, Link } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import ConfirmDeleteDialog from './../../components/ConfirmDeleteDialog.js';

export default function ChartRow(props) {
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    return (
        <TableRow>
            <TableCell>
                {props.chart.caption}
            </TableCell>
            <TableCell>
                {props.chart.metric.monitor.displayName}
            </TableCell>
            <TableCell>
                {props.chart.type}
            </TableCell>
            <TableCell align="right">
                <IconButton variant="contained" onClick={() => props.moveChartUp(props.chart.id)}>
                    <ArrowUpwardIcon />
                </IconButton>
                <IconButton variant="contained" onClick={() => props.moveChartDown(props.chart.id)}>
                    <ArrowDownwardIcon />
                </IconButton>
                <IconButton component={Link} to={'/ship/' + props.ship.id + '/chart/' + props.chart.id}>
                    <CreateIcon />
                </IconButton>
                <IconButton variant="contained" color="error" onClick={() => setShowDeleteDialog(true)}>
                    <DeleteIcon />
                </IconButton>
            </TableCell>
            <ConfirmDeleteDialog open={showDeleteDialog} onClose={() => { setShowDeleteDialog(false) }} onDelete={() => props.deleteChart(props.chart.id)}>
                <p>
                    You are about to delete the chart <b>{props.chart.displayName}</b><br />
                </p>
                <p>
                    Are you sure?
                </p>
            </ConfirmDeleteDialog>
        </TableRow>
    )
}