/**
 * Created by Robin on 04/01/2023.
 */

import React, {useContext, useEffect, useState} from "react";
import { useParams, useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';

import APIContext from './../context/APIContext.js';

import InvalidResource from './../components/InvalidResource.js';
import ShipSelector from './../components/ShipSelector.js';

function DeviceView(props) {
    const API = useContext(APIContext);
    const [device, setDevice] = useState(null);
    const [updatePending, setUpdatePending] = useState(false);

    const [name, setName] = useState('');
    const [shipId, setShipId] = useState(null);

    const navigate = useNavigate();
    let {id} = useParams();

    useEffect(() => {
        API.getDevice(id).then((deviceData) => {
            setDevice(deviceData);
        });

    }, [id, API]);

    useEffect(() => {
        if (device) {
            setName(device.name);
            setShipId(device.ship.id);
        }
    }, [device]);

    const updateDevice = () => {
        const data = {
            name: name,
            shipId: shipId,
        };

        setUpdatePending(true);

        API.setDevice(id, data).then((res) => {
            if (res) {
                navigate('/device');
            } else {
                alert('Failed to update device');
            }
            setUpdatePending(false);
        })
    };

    if (device === false) {
        return (
            <InvalidResource type="device" id={id}/>
        )
    } else if (device === null) {
        return <p>Loading...</p>;
    } else {
        return (
            <React.Fragment>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{marginBottom: '20px'}}
                    spacing="10px"
                >
                    <Grid item>
                        <IconButton size="small" variant="text" onClick={() => navigate(-1)}>
                            <ArrowBackIcon/>
                        </IconButton >
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <Typography variant="h5" component="h2">
                                <b>Device:</b> {device.name}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Card>
                    <CardHeader title={
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography variant="h6">
                                Device details
                            </Typography>
                            <LoadingButton loading={updatePending} variant="contained" startIcon={<SaveIcon />}
                                           onClick={updateDevice}>
                                Save
                            </LoadingButton>
                        </Grid>
                    }/>
                    <CardContent>
                        <Grid container spacing={{md: 4, xs: 0}}>
                            <Grid item xs={12} md={6}>
                                <TextField fullWidth label="Name" style={{margin: '10px 0px'}} variant="outlined" value={name} onChange={(e) => setName(e.target.value)}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ShipSelector allowNull={true} value={shipId} onChange={(e) => setShipId(e.target.value)}/>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </React.Fragment>
        );
    }
}

export default DeviceView;