/**
 * Created by Robin on 26/07/2023.
 */

import React, {useContext, useEffect, useState} from "react";

import IntegerInput from './IntegerInput.js';
import MapInput from './MapInput.js';

export default function DataField(props) {
    if (props.parameter) {
        if (props.parameter.type.toUpperCase() === "INTEGER") {
            return <IntegerInput {...props} />
        } else if (props.parameter.type.toUpperCase() === "MAP") {
            return <MapInput {...props} />
        } else {
            return <p>Invalid Type</p>;
        }
    } else {
        return <p>No Param</p>;
    }
}