/**
 * Created by Robin on 27/07/2023.
 */

import React, {useContext, useEffect, useState} from "react";
import { useParams, useNavigate } from 'react-router-dom';

import APIContext from './../../context/APIContext.js';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import CheckIcon from '@mui/icons-material/Check';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ReportIcon from '@mui/icons-material/Report';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

export default function Dashboard(props) {
    const API = useContext(APIContext);
    const [ships, setShips] = useState([]);
    const [alarms, setAlarms] = useState([]);
    const [tickets, setTickets] = useState([]);


    useEffect(() => {
        API.getShips().then((ships) => {
            setShips(ships);
        });

        API.getAllOpenAlarms().then((alarms) => {
            setAlarms(alarms);
        });

        API.getTickets().then((tickets) => {
            setTickets(tickets);
        });
    }, [API]);

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="flex-start"
            spacing={2}
        >
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card>
                    <CardContent>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            height: '200px',
                            color: (theme) => theme.palette.primary.main
                        }}>
                            <DirectionsBoatIcon style={{ fontSize: 60 }}/>
                            <div style={{ fontSize: 20, color: 'initial' }}>
                                Tracking <b>{ships ? ships.length : 0}</b> ship(s)
                            </div>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            {
                alarms && alarms.length === 0 &&
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card>
                            <CardContent>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "space-evenly",
                                    height: '200px',
                                    color: "darkgreen"
                                }}>
                                    <CheckIcon style={{ fontSize: 60 }}/>
                                    <div style={{ fontSize: 20, color: 'initial' }}>
                                        No Open Alarms
                                    </div>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
            }
            {
                alarms && alarms.filter((alarm) => alarm.state === 'WARN').length > 0 &&
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Card>
                        <CardContent>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "space-evenly",
                                height: '200px',
                                color: "darkorange"
                            }}>
                                <WarningAmberIcon style={{ fontSize: 60 }}/>
                                <div style={{ fontSize: 20, color: 'initial' }}>
                                    <b>{alarms.filter((alarm) => alarm.state === 'WARN').length}</b> Warning Alarm(s)
                                </div>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            }
            {
                alarms && alarms.filter((alarm) => alarm.state === 'CRITICAL').length > 0 &&
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Card>
                        <CardContent>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "space-evenly",
                                height: '200px',
                                color: "darkred"
                            }}>
                                <ReportIcon style={{ fontSize: 60 }}/>
                                <div style={{ fontSize: 20, color: 'initial' }}>
                                    <b>{alarms.filter((alarm) => alarm.state === 'CRITICAL').length}</b> Critical Alarm(s)
                                </div>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            }
            {
                /*
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card>
                    <CardContent>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            height: '200px',
                            color: (theme) => theme.palette.primary.main
                        }}>
                            <HelpCenterIcon style={{ fontSize: 60 }}/>
                            <div style={{ fontSize: 20, color: 'initial' }}>
                                <b>{tickets ? tickets.filter((ticket) => ticket.status === 'OPEN').length : 0}</b> Open Ticket(s)
                            </div>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
                 */
            }
        </Grid>
    );
}