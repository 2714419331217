/**
 * Created by Robin on 26/07/2023.
 */

import React, {useContext, useEffect, useState} from "react";

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

export default function MapInput(props) {

    if (props.parameter.multi) {
        return (
            <TextField select fullWidth style={{margin: '10px 0px'}}
                       value={props.data[props.keyName] !== undefined ? props.data[props.keyName] : []}
                       label={props.parameter.displayName}
                       onChange={(e) => {

                       }}
                       SelectProps={{
                           multiple: true,
                           onChange: (e) => {
                               console.log(e.target.value);
                               props.setData(props.keyName, e.target.value);
                           }
                       }}
            >
                {
                    props.parameter.values.map((value) => {
                        return <MenuItem key={value.key} value={value.key}>{value.value}</MenuItem>
                    })
                }
            </TextField>
        );
    } else {
        return (
            <TextField select fullWidth style={{margin: '10px 0px'}}
                       value={props.data[props.keyName] !== undefined ? props.data[props.keyName] : ''}
                       label={props.parameter.displayName}
                       onChange={(e) => {
                           props.setData(props.keyName, e.target.value);
                       }}
            >
                {
                    props.parameter.values.map((value) => {
                        return <MenuItem key={value.key} value={value.key}>{value.value}</MenuItem>
                    })
                }
            </TextField>
        );
    }
}