/**
 * Created by Robin on 27/07/2023.
 */

import React, {useContext, useEffect, useState} from "react";
import { useParams, useNavigate } from 'react-router-dom';

import APIContext from './../../../context/APIContext.js';

import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import CheckIcon from '@mui/icons-material/Check';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ReportIcon from '@mui/icons-material/Report';

export default function AlarmDisplay(props) {
    const API = useContext(APIContext);
    const [alarms, setAlarms] = useState(false);

    const updateAlarms = () => {
        API.getShipOpenAlarms(props.ship.id).then((alarms) => {
            setAlarms(alarms);
        });
    };

    useEffect(() => {
        updateAlarms();

        if (props.updateInterval) {
            const interval = setInterval(() => {
                updateAlarms();
            }, props.updateInterval * 1000);
            return () => clearInterval(interval);
        }
    }, [API, props.ship]);

    if (alarms) {
        if (alarms.length === 0) {
            return (
                <Box key="none" sx={{
                    display: 'inline-flex',
                    color: 'darkgreen',
                    border: '1px solid darkgreen',
                    borderRadius: '5px',
                    backgroundColor: '#e6f7eb'
                }}>
                    <CheckIcon fontSize="small" color="darkgreen"/>
                </Box>
            );
        } else {
            return (
                <Box sx={{
                    display: 'inline-flex',
                    gap: '5px'
                }}>
                    {
                        alarms.filter((alarm) => alarm.state === 'WARN').length > 0 &&
                        <Box key="warn" sx={{
                            display: 'inline-flex',
                            color: 'darkorange',
                            border: '1px solid darkorange',
                            borderRadius: '5px',
                            backgroundColor: '#f5ecd3',
                            gap: '5px',
                            alignItems: 'center',
                            padding: '5px'
                        }}>
                            <WarningAmberIcon fontSize="small" color="darkorange"/>
                            <span><b>{alarms.filter((alarm) => alarm.state === 'WARN').length}</b></span>
                        </Box>
                    }
                    {
                        alarms.filter((alarm) => alarm.state === 'CRITICAL').length > 0 &&
                        <Box key="critical" sx={{
                            display: 'inline-flex',
                            color: 'darkred',
                            border: '1px solid darkred',
                            borderRadius: '5px',
                            backgroundColor: '#ffdee6',
                            gap: '5px',
                            alignItems: 'center',
                            padding: '5px'
                        }}>
                            <ReportIcon fontSize="small" color="darkred"/>
                            <span><b>{alarms.filter((alarm) => alarm.state === 'CRITICAL').length}</b></span>
                        </Box>
                    }
                </Box>
            )
        }
    } else {
        return [];
    }
}