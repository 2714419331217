/**
 * Created by Robin on 19/12/2022.
 */

import React from "react";

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';

import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import TitleIcon from '@mui/icons-material/Title';
import FlagIcon from '@mui/icons-material/Flag';
import CachedIcon from '@mui/icons-material/Cached';

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';

import ReactTimeAgo from 'react-time-ago';
import TimeAgoTooltipWrapper from './../components/TimeAgoTooltipWrapper.js';
import TicketPost from './TicketPost.js';

TimeAgo.addDefaultLocale(en);

const ICON_MAPPING = {
    CHANGE_ASSIGNEE: <PersonSearchIcon />,
    CHANGE_SHIP: <DirectionsBoatIcon />,
    CHANGE_TITLE: <TitleIcon />,
    CHANGE_PRIORITY: <FlagIcon />,
    CHANGE_STATUS: <CachedIcon />
};

const CONTENT_MAPPING = {
    CHANGE_ASSIGNEE: ChangeAssigneeAction,
    CHANGE_SHIP: ChangeShipAction,
    CHANGE_TITLE: ChangeTitleAction,
    CHANGE_PRIORITY: ChangePriorityAction,
    CHANGE_STATUS: ChangeStatusAction,
};

function ChangeStatusAction(props) {
    const statusFormat = (status) => {
        if (status === 'CLOSED') {
            return 'Closed';
        } else if (status === 'HOLD') {
            return 'On Hold';
        } else {
            return 'Open';
        }
    };

    return (
        <Grid
            container
            spacing={'5px'}
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Typography style={{fontSize: '14px'}}>Changed status from <b><del>{statusFormat(props.action.data.prev)}</del></b> to <b>{statusFormat(props.action.data.current)}</b></Typography>
        </Grid>
    );
}

function ChangePriorityAction(props) {
    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return (
        <Grid
            container
            spacing={'5px'}
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Typography style={{fontSize: '14px'}}>Changed priority from <b><del>{capitalize(props.action.data.prev)}</del></b> to <b>{capitalize(props.action.data.current)}</b></Typography>
        </Grid>
    );
}

function ChangeTitleAction(props) {
    return (
        <Grid
            container
            spacing={'5px'}
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Typography style={{fontSize: '14px'}}>Changed title to</Typography>
            <Tooltip title={<span><b>Previous title:</b> {props.action.data.prev}</span>}>
                <Typography style={{fontSize: '12px'}}>{props.action.data.current}</Typography>
            </Tooltip>
        </Grid>
    );
}

function ChangeShipAction(props) {
    if (props.action.data.prev === null) {
        return (
            <Grid
                container
                spacing={'5px'}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Typography style={{fontSize: '14px'}}>Assigned ticket to</Typography>
                <Button variant="text" style={{textTransform: 'none'}} startIcon={<DirectionsBoatIcon />}>{props.action.data.current.name}</Button>
            </Grid>
        );
    } else if (props.action.data.current === null) {
        return (
            <Grid
                container
                spacing={'5px'}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Typography style={{fontSize: '14px'}}>Unassigned ticket from</Typography>
                <Button variant="text" style={{textTransform: 'none'}} startIcon={<DirectionsBoatIcon />}><del>{props.action.data.prev.name}</del></Button>
            </Grid>
        )
    } else {
        return (
            <Grid
                container
                spacing={'5px'}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Typography style={{fontSize: '14px'}}>Changed ship from</Typography>
                <Button variant="text" style={{textTransform: 'none'}} startIcon={<DirectionsBoatIcon />}><del>{props.action.data.prev.name}</del></Button>
                <Typography style={{fontSize: '14px'}}>to</Typography>
                <Button variant="text" style={{textTransform: 'none'}} startIcon={<DirectionsBoatIcon />}>{props.action.data.current.name}</Button>
            </Grid>
        );
    }
}

function ChangeAssigneeAction(props) {
    if (props.action.data.prev === null) {
        return (
            <Grid
                container
                spacing={'5px'}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Typography style={{fontSize: '14px'}}>Assigned ticket to</Typography>
                <Button variant="text" style={{textTransform: 'none'}} startIcon={<AccountCircleIcon />}>{props.action.data.current.displayName}</Button>
            </Grid>
        );
    } else if (props.action.data.current === null) {
        return (
            <Grid
                container
                spacing={'5px'}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Typography style={{fontSize: '14px'}}>Unassigned ticket from</Typography>
                <Button variant="text" style={{textTransform: 'none'}} startIcon={<AccountCircleIcon />}><del>{props.action.data.prev.displayName}</del></Button>
            </Grid>
        )
    } else {
        return (
            <Grid
                container
                spacing={'5px'}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Typography style={{fontSize: '14px'}}>Changed ticket assignment from</Typography>
                <Button variant="text" style={{textTransform: 'none'}} startIcon={<AccountCircleIcon />}><del>{props.action.data.prev.displayName}</del></Button>
                <Typography style={{fontSize: '14px'}}>to</Typography>
                <Button variant="text" style={{textTransform: 'none'}} startIcon={<AccountCircleIcon />}>{props.action.data.current.displayName}</Button>
            </Grid>
        );
    }
}

function TicketTimelineAction(props) {
    const TimelineContentBox = (props) => {
        let items = [];

        let index = 0;
        if (Array.isArray(props.children)) {
            for (let child of props.children) {
                items.push(<Grid key={index++} item>{child}</Grid>)
            }
        } else {
            items.push(<Grid key={index++} item>{props.children}</Grid>)
        }

        return (
            <Grid
                style={{ height: 66 }}
                spacing={'5px'}
                container
                direction="row"
                justifyContent={props.position === 'left' ? 'flex-end' : 'flex-start'}
                alignItems="center"
            >

                {items}
            </Grid>
        )
    };

    if (props.action.action === "POST") {
        return (
            <React.Fragment>
                <TicketPost post={props.action}/>
                <TimelineItem style={{minHeight: '13px'}}>
                    <TimelineOppositeContent color="textSecondary" sx={{py: '0px', px: 1}}>

                    </TimelineOppositeContent>
                    <TimelineSeparator>

                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{py: '0px', px: 1}}>

                    </TimelineContent>
                </TimelineItem>
            </React.Fragment>
        )
    } else {

        let ContentItem = React.createElement(CONTENT_MAPPING[props.action.action], {action: props.action});

        return (
            <TimelineItem>
                <TimelineOppositeContent color="textSecondary" sx={{py: '0px', px: 1}}>
                    <TimelineContentBox position="left">
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-end">
                            <Button size="small" variant="text" style={{textTransform: 'none'}}
                                    startIcon={<AccountCircleIcon />}>{props.action.user.displayName}</Button>
                            <Typography style={{paddingRight: '5px', fontSize: '12px'}}>
                                <ReactTimeAgo
                                    date={new Date(props.action.createdAt)}
                                    locale="en-US"
                                    timeStyle="round"
                                    wrapperComponent={TimeAgoTooltipWrapper}/>
                            </Typography>
                        </Grid>
                    </TimelineContentBox>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot color="primary">
                        {ICON_MAPPING[props.action.action]}
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{py: '0px', px: 1}}>
                    <div style={{paddingLeft: '5px'}}>
                        <TimelineContentBox>
                            {ContentItem}
                        </TimelineContentBox>
                    </div>
                </TimelineContent>
            </TimelineItem>
        )
    }
}

export default TicketTimelineAction;