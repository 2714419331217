/**
 * Created by Robin on 04/01/2023.
 */

import React, {useContext, useState} from "react";
import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';

import APIContext from './../context/APIContext.js';

import UserSelector from './../components/UserSelector.js';


function VolumeMapCreate(props) {
    const API = useContext(APIContext);
    const [updatePending, setUpdatePending] = useState(false);

    const [displayName, setDisplayName] = useState('');

    const navigate = useNavigate();

    const createShip = () => {
        const data = {
            displayName: displayName
        };

        setUpdatePending(true);

        API.createVolumeMap(data).then((res) => {
            if (res) {
                navigate('/volume_map/' + res.id, {replace: true})
            } else {
                alert("Failed to create volume map!");
                setUpdatePending(false);
            }
        })
    };


    const isInputValid = () => {
        if (displayName.length < 3) {
            return false;
        }

        return true;
    };

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                style={{marginBottom: '20px'}}
                spacing="10px"
            >
                <Grid item>
                    <IconButton size="small" variant="text" onClick={() => navigate(-1)}>
                        <ArrowBackIcon/>
                    </IconButton >
                </Grid>
                <Grid item>
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <Typography variant="h5" component="h2">
                            <b>Create volume map</b>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Card>
                <CardHeader title={
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h5">
                            Volume map details
                        </Typography>
                        <LoadingButton loading={updatePending} variant="contained" startIcon={<SaveIcon />}
                                       onClick={createShip} disabled={!isInputValid()}>
                            Save
                        </LoadingButton>
                    </Grid>
                }/>
                <CardContent>
                    <Grid container spacing={{md: 4, xs: 0}}>
                        <Grid item xs={12} md={12}>
                            <TextField fullWidth label="Name" style={{margin: '10px 0px'}} variant="outlined" value={displayName} onChange={(e) => setDisplayName(e.target.value)}/>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </React.Fragment>
    );
}

export default VolumeMapCreate;