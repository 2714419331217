/**
 * Created by Robin on 04/01/2023.
 */

import React, {useContext, useEffect, useState} from "react";

import Skeleton from '@mui/material/Skeleton';

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import APIContext from './../context/APIContext.js';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import ListCardHeader from './../components/ListCardHeader.js';
import UserButton from './../components/UserButton.js';

import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

function UserList(props) {
    const API = useContext(APIContext);
    const [users, setUsers] = useState(false);

    useEffect(() => {
        API.getUsers().then((users) => {
            if (users !== false) {
                setUsers(users);
            }
        });
    }, [API]);

    const UserCard = () => {
        if (users === false) {
            return (
                <Card>
                    <ListCardHeader title="User list" createLink="/user/create"/>
                    <CardContent>
                        <Skeleton variant="rounded" height={20} width="50%" animation="pulse"/>
                        <br />
                        <Skeleton variant="rounded" height={20} width="25%" animation="pulse"/>
                        <br />
                        <Skeleton variant="rounded" height={20} width="75%" animation="pulse"/>
                        <br />
                        <Skeleton variant="rounded" height={20} width="50%" animation="pulse"/>
                    </CardContent>
                </Card>
            );
        } else {
            return (
                <Card>
                    <ListCardHeader title="User list" createLink="/user/create"/>
                    <CardContent sx={{
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingBottom: '0px !important'
                    }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Display name</TableCell>
                                    <TableCell>E-mail</TableCell>
                                    <TableCell>Phone</TableCell>
                                    <TableCell>Notifications</TableCell>
                                    <TableCell>Active</TableCell>
                                    <TableCell>Admin</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map((user) => (
                                    <TableRow
                                        key={user.id}

                                    >
                                        <TableCell><UserButton user={user}/></TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>{user.phone}</TableCell>
                                        <TableCell>{user.allowAlarmNotifications === 1 ? <CheckIcon /> : <ClearIcon />}</TableCell>
                                        <TableCell>{user.active === 1 ? <CheckIcon /> : <ClearIcon />}</TableCell>
                                        <TableCell>{user.admin === 1 ? <CheckIcon /> : <ClearIcon />}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            );
        }
    }
    return <Grid container spacing={24}>
        <Grid item xs={12} sm={12}>
            <UserCard />
        </Grid>
    </Grid>
}

export default UserList;