/**
 * Created by Robin on 04/01/2023.
 */

import React, {useContext, useEffect, useState} from "react";

import Skeleton from '@mui/material/Skeleton';

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import APIContext from './../context/APIContext.js';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import ListCardHeader from './../components/ListCardHeader.js';
import ShipButton from './../components/ShipButton.js';
import UserButton from './../components/UserButton.js';

function ShipList(props) {
    const API = useContext(APIContext);
    const [ships, setShips] = useState(false);

    useEffect(() => {
        API.getShips().then((ships) => {
            if (ships !== false) {
                setShips(ships);
            }
        });
    }, [API]);

    const ShipCard = () => {
        if (ships === false) {
            return (
                <Card>
                    <ListCardHeader title="Ship list" createLink="/ship/create" />
                    <CardContent>
                        <Skeleton variant="rounded" height={20} width="50%" animation="pulse"/>
                        <br />
                        <Skeleton variant="rounded" height={20} width="25%" animation="pulse"/>
                        <br />
                        <Skeleton variant="rounded" height={20} width="75%" animation="pulse"/>
                        <br />
                        <Skeleton variant="rounded" height={20} width="50%" animation="pulse"/>
                    </CardContent>
                </Card>
            );
        } else {
            return (
                <Card>
                    <ListCardHeader title="Ship list" createLink="/ship/create" />
                    <CardContent sx={{
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingBottom: '0px !important'
                    }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>MMSI</TableCell>
                                    <TableCell>Safety User</TableCell>
                                    <TableCell>Technical User</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ships.map((ship) => (
                                    <TableRow
                                        key={ship.id}

                                    >
                                        <TableCell><ShipButton ship={ship}/></TableCell>
                                        <TableCell>{ship.mmsi}</TableCell>
                                        <TableCell><UserButton user={ship.safetyUser} /></TableCell>
                                        <TableCell><UserButton user={ship.technicalUser} /></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            );
        }
    }
    return <Grid container spacing={24}>
        <Grid item xs={12} sm={12}>
            <ShipCard />
        </Grid>
    </Grid>
}

export default ShipList;